@import "../../Reusable/colors";

.waitingList {
  padding: 30px 20px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin-right: -80px;

  .form-group {
    margin-bottom: 8px;
  }

  .form-row {
    .form-group {
      col {
        border: #0063db;
      }
    }
  }

  .noSpace {
    margin-bottom: 0;
  }

  .erroricon {
    background-position: right calc(0.375em + 0.7rem) center;
  }

  .inputgroup-right-radius {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .waitingList {
    padding: 20px;
    margin-right: 0;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 850px) {
  .waitingList {
    .form-row {
      flex-direction: column;
    }
  }
}