@import "../../Reusable/colors";
.instagram {
  .slider-container {
    width: 90%;
    display: block;
    margin: auto;
  }
  .slick-next:before {
    color: $pink;
    font-size: 50px;
  }
  .slick-prev:before {
    color: $pink;
    font-size: 50px;
  }
  .slick-prev {
    width: 35px;
    height: 60px;
    left: -75px;
    color: black !important;
  }
  .slick-next {
    width: 35px;
    height: 60px;
    right: -70px;
    color: black;
  }
  .slick-prev:before {
    font-family: "FontAwesome";
    content: url(../../../Assets/Aboutus/chevron-left.svg);
  }
  .slick-next:before {
    font-family: "FontAwesome";
    content: url(../../../Assets/Aboutus/chevron-right.svg);
  }
  .slick-list {
    .slick-track {
      display: flex;
      align-items: center;
      .itemContainer {
        border: 0px solid red;
        height: auto;
        .image {
          position: relative;
          img {
            border: 0px solid blue;
            margin: auto;
            height: auto;
            width: auto;
            max-width: 290px;
          }
          .content-overlay {
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            height: 99%;
            width: 80%;
            opacity: 0;
            -webkit-transition: all 0.4s ease-in-out 0s;
            -moz-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
          }
          &:hover .content-overlay {
            opacity: 1;
          }
        }
      }
    }
  }
}
