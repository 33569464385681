@import "../Reusable/colors";

.companies-form {
  margin: auto;
  width: 700px !important;
  padding: 0 30px 10px;

  .form-check {
    margin-top: 10px;

    label {
      margin-top: 0;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .form-text {
    color: red;

    p {
      font-size: 14px;
    }
  }

  .info-box {
    width: 17vw;
  }

  .erroricon {
    background-position: right calc(0.375em + 0.7rem) center;
  }

  .inputgroup-right-radius {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

@media only screen and (max-width: 850px) {
  .companies-form {
    .form-row {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 766px) {
  .companies-form {
    width: auto !important;

    .col-md-6:nth-child(1) {
      padding-right: 0.75rem;
    }

    .info-box {
      width: 50vw;
    }
  }
}