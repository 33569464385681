@import "../../Reusable/colors";
.eventConfirmModal {
    .modal-dialog {
        max-width: 1000px;
    }
    .paragraphHeading {
        text-align: center;
    }
    .a {
        margin: 5vh 0;
    }
    .b {
        margin: 30px 0;
    }
    .formContainer {
        height: auto;
        width: 90%;
        margin: auto;
    }
    .checkIcon {
        display: block;
        margin: auto;
        svg {
            display: block;
            margin: auto;
        }
    }
    .confirmationContainer {
        display: block;
        width: 90%;
        margin: auto;
        margin-top: 30px;
    }
}

.eventConfirmPopUp {
    .lej {
        padding: 2px 30px;
    }
}

@media only screen and (max-width: 1280px) {
    .eventConfirmModal {
        .modal-dialog {
            max-width: 90%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .eventConfirmPopUp {
        .lej {
            width: 100%;
            padding: 5px 30px;
        }
    }
    .eventConfirmModal {
        .modal-dialog {
            max-width: 95%;
        }
    }
}

@media only screen and (max-width: 625px) {
    .eventConfirmPopUp {
        bottom: 10px;
        .lej {
            width: 100%;
            padding: 5px 30px;
        }
    }
}

@media only screen and (max-width: 400px) {
    .eventConfirmModal {
        .formContainer {
            width: 95%
        }
    }
}

@media only screen and (max-height: 500px) {
    .eventConfirmModal {
        .a {
            margin: 40px 0;
        }
    }
}