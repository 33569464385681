@import "../Reusable/colors";

.faq {
  font-style: normal;
  overflow-x: hidden;
  max-width: 100%;

  h6 {
    font-size: 20px;
  }

  .content {
    width: 1200px;
    margin: auto;
  }

  .faqDivider {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 4vh;
    text-decoration: underline;
  }

  .element {
    padding-bottom: 5vh;
  }

  .top {
    margin-top: 58px;
    height: 93.8vh;
    background-image: url("../../Assets/Faq/faq_top.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;

    .bg {
      height: 100%;
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        position: relative;
        top: 15%;

        h1 {
          color: white;
          font-size: 48px;
          font-weight: 700;
          margin: auto;
        }

        p {
          color: white;
          font-size: 28px;
          padding-top: 1vw;
        }
      }
    }
  }

  h3 {
    text-align: center;
  }

  .topButtonContainer {
    display: flex;
    justify-content: center;
  }

  .generaltButton,
  .bilisterButton,
  .udlejerButton {
    border: none;
    margin: 10px;
    width: 90%;
  }

  .generaltButton {
    background-color: $darkgrey !important;
  }

  .bilisterButton {
    background-color: $blue !important;
  }

  .udlejerButton {
    background-color: $pink !important;
  }

  .questions {
    text-align: left;
  }

  p {
    white-space: pre-wrap;
  }

  /*GENERAL QUESTIONS SECTION */
  .generalQuestions {
    .content {
      #headingGeneral {
        text-align: center;
      }

      .element1 {
        .questionContainer {
          display: flex;
          margin-bottom: 3vh;

          .imageContainer {
            img {
              height: 30px;
              width: 30px;
              padding-bottom: 2px;
            }
          }

          .questionLabel {
            font-weight: bold;
            font-size: 22px;
          }

          .textContainer {
            margin-left: 20px;
            cursor: pointer;
            position: relative;
          }

          .questionAnswer p {
            margin-top: 5px;
            position: absolute;
            left: -1000em;
          }
        }
      }
    }
  }

  /*FOR BILISTER QUESTIONS SECTION*/
  .forBilister {
    .content {
      #headingforBilister {
        text-align: center;
      }

      .element {
        .questionContainer {
          display: flex;
          margin-bottom: 3vh;

          .imageContainer {
            img {
              height: 30px;
              width: 30px;
              padding-bottom: 2px;
            }
          }

          .questionLabel {
            font-weight: bold;
            font-size: 22px;
          }

          .textContainer {
            margin-left: 20px;
            cursor: pointer;
            position: relative;
          }

          .questionAnswer p {
            margin-top: 5px;
            position: absolute;
            left: -1000em;
          }
        }
      }
    }
  }

  /*FOR UDLEJER QUESTIONS SECTION*/
  .forUdlejer {
    .content {
      #headingforUdlejer {
        text-align: center;
      }

      .element {

        #generalForUdlejer,
        #monthlyForUdlejer,
        #eventForUdlejer {
          font-size: 28px;
          margin-left: 60px;
          margin-bottom: 30px;
        }

        .questionContainer {
          display: flex;
          margin-bottom: 3vh;

          .imageContainer {
            img {
              height: 30px;
              width: 30px;
              padding-bottom: 2px;
            }
          }

          .questionLabel {
            font-weight: bold;
            font-size: 22px;
          }

          .textContainer {
            margin-left: 20px;
            cursor: pointer;
            position: relative;

            li {
              font-size: 18px;
            }
          }

          .questionAnswer p {
            margin-top: 5px;
            position: absolute;
            left: -1000em;
          }
        }
      }
    }
  }

  /*BOTTOM SECTION*/
  .bottom {
    height: 85vh;
    background: url("../../Assets/Faq/parkshare-employees-laptop.jpeg") no-repeat left 40%;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    color: white;
    margin-bottom: 0;

    .background {
      height: 100%;
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .element1 {
          display: flex;
          flex-direction: column;

          a {
            text-decoration: none;
          }

          #headingBottom {
            font-size: 36px;
          }

          #btn-kontakt {
            width: 25%;
            padding: 1.5% 0;
            font-size: 25px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            margin: 3% auto 6% auto;

            #btn-text {
              align-self: center;
            }
          }

          #btn-kontakt:hover {
            box-shadow: 0px 3px 10px black;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .faq {
    .top {
      height: 60vw;

      .bg {
        .content {
          width: 80%;
        }
      }
    }

    .generalQuestions {
      .content {
        width: 90%;
      }
    }

    .forBilister {
      .content {
        width: 90%;
      }
    }

    .forUdlejer {
      .content {
        width: 90%;
      }
    }

    .bottom {
      height: 60vh;

      .background {
        .content {
          width: 100%;

          .element1 {
            #headingBottom {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .faq {
    .top {
      .bg {
        .content {
          h1 {
            font-size: 40px;
          }
        }
      }
    }

    .generalQuestions {
      .content {
        width: 90%;
      }
    }

    .forBilister {
      .content {
        width: 90%;
      }
    }

    .forUdlejer {
      .content {
        width: 90%;
      }
    }

    .bottom {
      .background {
        .content {
          width: 100%;

          .element1 {
            #headingBottom {
              text-align: center;
            }

            #btn-kontakt {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .faq {
    .regular-button {

      .generaltButton,
      .bilisterButton,
      .udlejerButton {
        width: 95% !important;
        font-size: 0.75em;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .faq {
    .generalQuestions {
      .content {
        .element {
          margin-top: 30px;

          .textContainer {
            margin-left: 10px;
          }
        }
      }
    }

    .forBilister {
      .content {
        .element {
          margin-top: 30px;

          #generalForBilister,
          #monthlyForBilister,
          #eventForBilister {
            font-size: 24px;
          }

          .textContainer {
            margin-left: 10px;
          }
        }
      }
    }

    .forUdlejer {
      .content {
        .element {
          margin-top: 30px;

          .textContainer {
            margin-left: 10px;
          }
        }
      }
    }

    .bottom {
      height: 30vh;

      .background {
        .content {
          width: 100%;

          .element1 {
            #headingBottom {
              font-size: 24px;
              text-align: center;
            }

            #btn-kontakt {
              width: 173px;
              height: 50px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .faq {
    .top {
      height: 84.4vh !important;
    }
  }

  .bottom {
    height: 80vh !important;

    .background {
      height: 100%;
    }
  }
}