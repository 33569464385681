.popup {
    .modal-content {
        padding: 40px;
        text-align: center;

        h2 {
            font-weight: 700;
            font-size: 22px;
            margin-top: 15px;
        }

        p {
            width: 80%;
            margin: 0 auto 15px;
        }

        img {
            height: 50px;
        }

        button {
            width: 120px;
            margin: auto;
        }
    }
}