.parkingListComponent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;

  .parkingList {
    width: 100%;
  }

  .eventParkingListComponent {
    margin: auto;
    width: 1020px;

    .parkingListHeader {
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      padding: 35px 0px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .parkingListComponent {

    .eventParkingListComponent {
      width: 90vw;
    }
  }
}