@import "../../Reusable/colors";

.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding-top: 50px;
  margin: auto;

  .arrowContainer {
    font-weight: 800;
    font-size: 20px;
    color: $blue;
    padding: 8px 12px;
    margin: 0 4px;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: baseline;
    cursor: pointer;

    .pagination-item {
      display: flex;
      flex-grow: 1;
      flex-shrink: 2;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      color: white;
      font-weight: 800;
      font-size: 20px;
      background-color: $darkgrey;
      margin: 0 4px;
      border-radius: 5%;
    }

    .active {
      background-color: $pink;
    }

    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      font-size: 20px;
      margin: 0 8px;
      color: black;
      cursor: default;
    }
  }
}

@media only screen and (max-width: 500px) {
  .paginationContainer {
    width: 100%;
    .arrowContainer {
      display: none;
    }
  }
}