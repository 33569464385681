@import "../Reusable/colors";

.terms {
  margin-top: 100px;
  min-height: 100vh;

  h1 {
    font-weight: 700;
    font-size: 48px;
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    margin: 40px;
    text-align: center;
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 5px 0;
  }

  p,
  div {
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

  .content {
    max-width: 1200px;
    padding: 0 10px;
    display: block;
    margin: auto;
  }

  .intro {
    text-align: center;
    max-width: 1150px;
    margin: auto;
  }

  .for-drivers,
  .for-owners {
    display: flex;
    margin: 0 -25px 60px;
  }

  .column {
    margin: 0 25px;
    flex: 1;
  }

  .li {
    margin: 10px 0;

    img {
      height: 20px;
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .terms {
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 28px;
      margin: 20px;
    }

    h3 {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .terms {
    .content {
      padding: 0 10vw;

      .for-drivers,
      .for-owners {
        flex-direction: column;
        margin: 0 -25px 30px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .terms {
    .content {
      padding: 0 5vw;
    }
  }
}