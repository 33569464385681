@import "../Reusable/colors";

.rentOutForm {
  background-color: white;
  border-radius: 15px;
  color: black;

  .sub-heading {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .normaltext {
    font-size: 16px;
    font-weight: 400;
  }

  .bold {
    font-size: 18px;
    font-weight: 700;
  }

  .invalid {
    display: block;
    width: 100%;
    font-size: 80%;
    font-weight: 700;
    color: $invalidReqColor;
    margin-bottom: 16px;
  }

  .invalidReqColor {
    color: $invalidReqColor
  }


  .invalid2 {
    // display: flex;
    font-size: 18px;
    font-weight: 700;
    color: $invalidReqColor;
    // justify-content: center;
  }

  .msg {
    margin-top: 30px;
  }

  .typeParking {
    margin-bottom: 25px;
    margin-top: 30px;

    .content2 {
      .btnContainer {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
        color: white;
        display: flex;
        flex-direction: row;
        position: relative;

        div {
          width: 160px;
          height: 40px;
          text-align: center;
          padding-top: 5px;
        }

        .leftBtn {
          border-radius: 5px 0 0 5px;
        }

        .midBtn {
          border-radius: 0px;
          border-left: 1px solid $lightgrey;
        }

        .rightBtn {
          border-radius: 0 5px 5px 0;
          border-left: 1px solid $lightgrey;
        }

        .pinkBtn {
          background-color: $pink;
        }

        .blueBtn {
          background-color: $blue;
          cursor: default;
        }

        .greyBtn {
          background-color: grey;
        }

        .greyBtn:hover {
          color: white;
          background-color: $pink;
          cursor: pointer;
        }

        .timeBtn {
          background-color: #c4c4c4;
        }
      }
    }
  }

  .blue {
    background-color: $blue;
  }

  .pink {
    background-color: $pink;
  }

  .grey {
    background-color: gray;
  }

  .typeDesc {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .tidsTyper {
    margin-top: 30px;

    .timeOptions {
      display: flex;
      flex-wrap: wrap;

      .type0,
      .type1,
      .type2,
      .type3 {
        background-color: white;
        color: black;
        margin-bottom: 10px;
        width: 375px;
        height: 100px;
        padding: 10px 5px;
        border-radius: 5px;
        border: $lightgrey thin solid;
        cursor: pointer;

        .typeBox {
          margin-left: 10px;

          .typeHeading {
            font-weight: 600;
            font-size: 18px;
          }

          .typeContent {
            font-weight: 400;
          }
        }
      }

      .type3,
      .type2 {
        margin-right: 10px;
      }

      .type:focus {
        background-color: yellowgreen;
      }

      .activeType {
        background-color: $blue;
        color: white;
        border: none;
      }
    }

    p {
      margin-bottom: 3px;
    }
  }

  .tider {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;

    .box1 {
      margin-right: 20px;
      font-weight: 600;
    }

    .box2 {
      margin-right: 20px;

      input {
        width: 100px;
      }

      .passive {
        background-color: $lightgrey;
        pointer-events: none;
      }
    }

    .box3 {
      input {
        width: 100px;
      }

      .passive {
        background-color: $lightgrey;
        pointer-events: none;
      }
    }

    .box1Custom {
      padding-top: 25px;

      p {
        margin-bottom: 30px;
        margin-right: 20px;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    .box4 {
      margin-left: 20px;
      padding-top: 25px;

      label {
        margin-right: 20px;
        margin-left: 5px;
      }

      .greyBtn {
        background-color: dimgrey;
      }

      .greyBtn:hover {
        color: white;
        background-color: $pink;
        cursor: pointer;
      }

      .row1 {
        display: flex;

        div {
          margin-right: 10px;
          background-color: dimgray;
          color: white;
          padding: 4px 5px;
          border-radius: 5px;
          cursor: pointer;
        }

        .activeType {
          background-color: $blue;
          color: white;
        }
      }

      .row2 {
        display: flex;
        margin-top: 22px;

        div {
          margin-right: 10px;
          background-color: dimgray;
          color: white;
          padding: 4px 5px;
          border-radius: 5px;
          cursor: pointer;
        }

        .activeType {
          background-color: $blue;
          color: white;
        }
      }

      .row3 {
        display: flex;
        margin-top: 22px;

        div {
          margin-right: 10px;
          background-color: dimgray;
          color: white;
          padding: 4px 5px;
          border-radius: 5px;
          cursor: pointer;
        }

        .activeType {
          background-color: $blue;
          color: white;
        }
      }
    }
  }

  .tider2 {
    .timeBox {
      border: $lightgrey thin solid;
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 20px;

      .container1 {
        font-weight: 800;
        font-size: 16px;
      }

      .container2 {
        margin-top: 30px;

        .passive {
          background-color: #999999;
          pointer-events: none;
        }

        label {
          font-size: 16px;
          margin-right: 10px;
          position: absolute;
          margin-top: -25px;
        }

        input {
          margin-right: 20px;
          height: 30px;
          font-size: 16px;
          width: 100px;
          margin-bottom: 10px;
        }
      }

      .container3 {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        flex-wrap: wrap;

        .greyBtn {
          background-color: dimgrey;
        }

        .greyBtn:hover {
          color: white;
          background-color: $pink;
          cursor: pointer;
        }

        div {
          background-color: grey;
          padding: 6px 20px;
          color: white;
          margin-right: 10px;
          border-radius: 4px;
          white-space: nowrap;
          margin-bottom: 10px;
          width: 160px;
          text-align: center;
        }

        .activeType {
          background-color: $blue;
        }
      }
    }
  }

  .submitBtn {
    margin: auto;
    margin-top: 30px;
    height: 10vh;
    display: block;
    text-align: center;
    border: white thin solid;
    width: 200px;
    height: 55px;
    background-color: $pink;
    border-radius: 5px;

    p {
      color: white;
      font-size: 20px;
      font-weight: bold;
      padding: 5px 20px;
    }
  }

  .err {
    color: red;
  }
}

@media only screen and (max-width: 850px) {
  .rentOutForm {
    .bold {
      font-size: 14px;
      padding-left: 3px;
      margin-bottom: 2px;
    }

    .tidsTyper {
      .timeOptions {

        .type0,
        .type1,
        .type2,
        .type3 {
          width: 48%;
          height: auto;
          min-height: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .rentOutForm {
    .sub-heading {
      text-align: center;
    }

    .tider {
      .box4 {
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .rentOutForm {
    .form-row {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 500px) {
  .rentOutForm {
    .sub-heading {
      margin: 20px 10px;
    }
  }
}

@media only screen and (max-width: 460px) {
  .rentOutForm {
    .typeParking {
      .content2 {
        .btnContainer {
          flex-direction: column;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .rentOutForm {
    .monthButton {
      font-size: 14px;
    }

    .eventButton {
      font-size: 14px;
    }

    .tider2 {
      .timeBox {
        padding: 20px;
        padding-bottom: 10px;
        min-width: 200px;

        .container1 {
          font-size: 18px;
        }

        .container2 {
          display: flex;
          justify-content: space-between;

          label {
            margin-top: -25px;
          }

          label:nth-child(1) {
            left: 50%;
          }

          input {
            height: 30px;
            width: 49%;
            margin: auto;
          }
        }

        .container3 {
          div {
            width: 100%;
            margin: auto;
            margin-bottom: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .rentOutForm {
    .tidsTyper {
      .timeOptions {

        .type0,
        .type1,
        .type2,
        .type3 {
          width: 48%;
          height: auto;
          min-height: 100px;
          padding-top: 5px;

          .typeBox {
            margin-left: 5px;

            .typeHeading {
              font-size: 14px;
            }

            .typeContent {
              font-size: 14px;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .rentOutForm {
    .monthButton {
      font-size: 14px;
      padding: 8px 10px;
    }

    .eventButton {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .rentOutForm {
    .tidsTyper {
      .timeOptions {

        .type0,
        .type1,
        .type2,
        .type3 {
          margin-right: 0;
          width: 100%;
          height: auto;
          min-height: 90px;
          padding-top: 5px;

          .typeBox {
            margin-left: 5px;

            .typeHeading {
              font-size: 14px;
            }

            .typeContent {
              font-size: 14px;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}