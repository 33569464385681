@import "../Reusable/colors";

.info {
  position: relative;

  .info-icon {
    height: 18px;
    margin-left: 2px;
    transition: all 1s ease;

    &:hover+.info-box {
      display: block;
    }
  }

  .info-box {
    font-size: 14px;
    border: 1px solid $blue;
    border-radius: 4px;
    background-color: white;
    padding: 10px;
    width: 280px;
    display: none;
    position: absolute;
    left: 33px;
    top: -17px;

    &:before {
      content: "";
      position: absolute;
      left: -20px;
      top: 10px;
      border: 10px solid transparent;
      border-right: 10px solid $blue;
      transform: scaleY(0.6);
    }

    &:after {
      content: "";
      position: absolute;
      left: -18px;
      top: 11px;
      border: 9px solid transparent;
      border-right: 9px solid white;
      transform: scaleY(0.6);
    }
  }
}

// info box moves to the top
@media only screen and (max-width: 700px) {
  .info {
    .info-box {
      width: 50vw;
      left: auto;
      top: auto;
      bottom: 40px;
      right: -20px;

      // triangle border
      &:before {
        transform: rotate(-90deg) scaleY(0.6);
        top: auto;
        left: auto;
        bottom: -20px;
        right: 20px;
      }

      // triangle white
      &:after {
        transform: rotate(-90deg) scaleY(0.6);
        top: auto;
        left: auto;
        bottom: -18px;
        right: 21px;
      }
    }
  }
}

@media only screen and (max-width: 388px) {
  .info {
    .info-box {
      right: auto;
      left: 0;

      &:before {
        right: auto;
        left: 0;
      }

      &:after {
        right: auto;
        left: 0;
      }
    }
  }
}