@import "../Reusable/colors";
$fcolor: #383737;

footer {
  background: $fcolor;
  padding-top: 10vh;
  padding-bottom: 1vw;
  height: auto;

  .sectionDivider {
    max-width: 83.33%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .spaceUnder {
    margin-bottom: 1vw;
  }

  .leftFooter {
    color: white;

    ul {
      list-style: none;
      margin-bottom: 0px;

      li {
        font-size: 18px;
      }

      a {
        color: white;
        text-decoration: none;
      }

      a:hover {
        color: $pink !important;
      }

      .footerTitle {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .rightFooter {
    .footerLogo {
      margin-bottom: 0.5vw;
      width: 21vw;
    }

    .SoMeIcons {
      display: flex;
      justify-content: center;
      margin-bottom: 0.4vh;
      padding-left: 2.3vw;

      .iconContainer {
        list-style: none;

        a {
          justify-content: space-between;
        }
      }

      .footerIcon .cls-1 {
        fill: none;
        stroke: #fff;
        stroke-width: 10px;
      }

      .footerIcon .cls-2 {
        stroke: #fff;
        stroke-miterlimit: 10;
        fill: #fff;
      }

      .iconContainer:hover {
        .footerIcon {
          .cls-1 {
            stroke: $pink;
          }

          .cls-2 {
            fill: $pink;
            stroke: $pink;
          }
        }
      }

      .footerIcon {
        width: 3.8vw;
      }
    }
  }

  .copyRight {
    text-align: center;
    margin-top: 2vh;
    color: rgba(255, 255, 255, 0.192) !important;

    .termsAndCookies {
      width: 300px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    a {
      text-decoration: none !important;
      color: rgba(255, 255, 255, 0.192) !important;
    }
  }
}

@media only screen and (max-width: 1280px) {
  footer {
    .rightFooter {
      .footerLogo {
        width: 30vw;
      }

      .footerIcon {
        width: 5vw !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  footer {
    .sectionDivider {
      display: block;
      padding-bottom: 5vw;
    }

    .rightFooter {
      align-self: auto;
      padding-top: 5vw;

      .footerLogo {
        width: 50vw;
        padding-bottom: 4vw;
      }

      svg {
        transform: scale(3);
      }

      .SoMeIcons {
        justify-content: left;
        padding-left: 5vw;
        margin-bottom: 2vw;

        a {
          padding-right: 7vw;

          .footerIcon {
            width: 3vw !important;
          }
        }
      }
    }
  }
}