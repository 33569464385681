@import "../Reusable/colors";

.eventInputForm {
  padding: 30px 20px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  .regular-button {
    #btn-submit {
      background-color: $blue;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .eventInputForm {
    padding: 20px;
  }
}