@import "../Reusable/colors";
.contact {
    .content {
        margin: auto;
    }
    // HERO
    .top {
        margin-top: 58px;
        height: 93.8vh;
        background-image: url("../../Assets/Contact/woman-parkshare-parked-cars.jpg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        .bg {
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 99, 219, 0.5032387955182073) 0%, rgba(255, 0, 100, 0.500437675070028) 100%);
            .content {
                position: relative;
                top: 15%;
                width: 62.5%;
            }
        }
    }
    // CONTACT INFO
    .contact-info {
        .content {
            width: 1000px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2vh 0;
            .details {
                width: 400px;
                margin-right: 50px;
                .paragraphHeading {
                    margin-top: 4vh;
                }
                .sectionHeading {
                    margin-bottom: 1vh;
                    text-align: left;
                    padding-top: 0;
                }
                span {
                    height: 1px;
                    width: 100%;
                    background-color: #c2c2c2;
                    display: block;
                    margin: 2vh 0 2vh;
                }
            }
            .info-row {
                display: flex;
                justify-content: left;
                align-items: center;
                margin-bottom: 10px;
                p {
                    margin-bottom: 0;
                    margin-left: 10px;
                }
            }
            .info-row:nth-of-type(3) {
                align-items: flex-start;
                svg {
                    margin-top: 5px;
                }
            }
        }
    }
    // TRANSPORT
    .transport {
        background-color: $blue;
        color: white;
        margin-bottom: 0;
        .content {
            display: flex;
            .transport-image {
                width: 50%;
                position: relative;
                img {
                    height: 550px;
                    width: 100%;
                    object-fit: cover;
                }
                .overlay {
                    background: linear-gradient(0deg, rgba(0, 99, 219, 0.5032387955182073) 0%, rgba(255, 0, 100, 0.500437675070028) 100%);
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                }
            }
            .transport-details {
                display: flex;
                align-items: center;
                width: 50%;
                .details {
                    width: 70%;
                    margin-left: 5vw;
                    .sectionHeading {
                        margin-bottom: 2vh;
                        text-align: left;
                        padding-top: 0;
                    }
                    .moreSpace {
                        padding-bottom: 3vh;
                    }
                    .theKitchen {
                        font-size: 22px;
                    }
                    .transport-means {
                        margin-top: 3vh;
                        display: flex;
                        align-items: center;
                        .car-container {
                            margin-right: 50px;
                        }
                        .car {
                            height: 50px;
                            width: 90px;
                        }
                        .bus {
                            margin-right: 30px;
                            width: 110px;
                            height: auto;
                        }
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .contact {
        .top {
            height: 60vw;
            .bg {
                .content {
                    width: 80%;
                }
            }
        }
        .contact-info {
            .content {
                width: 80% !important;
            }
        }
        .transport {
            .content {
                .transport-details {
                    .details {
                        width: 85%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 970px) {
    .contact {
        /*  .sectionHeading {
            text-align: center !important;
        } */
        .spaceRight {
            margin-left: 4.5vw;
        }
        .contact-info {
            .content {
                flex-direction: column;
                align-items: center;
                .details {
                    width: 300px;
                    margin-bottom: 40px;
                    margin-right: 0;
                }
            }
        }
        .transport {
            .content {
                flex-direction: column;
                .transport-image {
                    width: 100%;
                    img {
                        height: 400px;
                    }
                }
                .transport-details {
                    padding: 4vh 2vw;
                    width: 100%;
                    /*   .theKitchen {
                        text-align: center;
                    } */
                    .transport-means {
                        flex-direction: column;
                        align-items: flex-start;
                        img {
                            margin-bottom: 10px;
                        }
                        .car-container {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    .contact {
        .contact-info {
            .details {
                width: 100% !important;
            }
        }
    }
}

@media only screen and (max-height: 500px) {
    .contact {
        .top {
            height: 84.4vh !important;
        }
    }
}