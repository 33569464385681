@import "../Reusable/colors";

.contact-form {
  width: 500px;
  padding: 15px 30px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  .form-group {
    margin-bottom: 0;

    .besked-box {
      height: 150px;
    }
  }
}

.inputgroup-right-radius {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

@media only screen and (max-width: 660px) {
  .contact-form {
    width: 100%;
  }
}