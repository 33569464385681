@import "../Reusable/colors";

.error {
  width: 100vw;
  height: 100vh;
  color: white;
  overflow-x: hidden;
  position: relative;
  max-width: 100%;

  & img {
    width: 130%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -2;
  }

  & .overlay {
    height: 100%;
    width: 100%;
    background: rgb(0, 99, 219);
    background: linear-gradient(0deg,
        rgba(0, 99, 219, 0.5032387955182073) 0%,
        rgba(255, 0, 100, 0.500437675070028) 100%);
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  & section {
    width: 1200px;
    padding: 40px 50px 0;
    margin: 0 auto;
  }

  & .content {
    width: 550px;

    & h1 {
      font-weight: 700;
      font-size: 72px;
      opacity: 0.6;
      line-height: 1;
    }

    & h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 1.1;
    }

    & .go-home-btn {
      font-weight: 800;
      margin: 20px 0 40px;
      padding: 0.5rem 1.5rem;
    }

    & p {
      font-size: 18px;
    }

    & a {
      color: white;
      font-weight: 700;
      transition: all 0.2s ease;
      text-decoration: underline;

      &:hover {
        color: $pink;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .error {
    & img {
      width: 140%;
    }

    & .section {
      width: auto;
    }

    & .content {
      width: 45vw;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .error {
    & .content {
      & h2 {
        font-size: 36px;
      }

      & h3 {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .error {
    & .content {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .error {
    & img {
      width: 100%;
    }

    & section {
      padding: 60px 20px 0;
    }
  }
}

@media (max-width: 330px) and (max-height: 700px) {
  .error {
    height: 120vh;
  }
}

@media (max-height: 580px) and (orientation: landscape) {
  .error {
    height: 150vh;
  }
}

@media (max-height: 330px) and (orientation: landscape) {
  .error {
    height: 200vh;
  }
}