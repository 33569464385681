@import "../Reusable/colors";

header {
  box-sizing: border-box;

  .navbar {
    box-shadow: -3px 10px 10px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    width: 100vw !important;
    height: auto;
    padding-bottom: 0;
    font-weight: 600;
    padding: 0.5rem 1.5rem 0 1.5rem;

    img {
      width: 200px;
      padding-bottom: 10px;
    }

    .navbar-toggler {
      margin-bottom: 10px;
    }

    .active {
      color: $blue !important;
      border-bottom: $blue solid 4px !important;
    }

    .nav-link {
      padding-top: 10px;
      padding-bottom: 17px;
    }

    .nav-link:hover {
      color: $pink !important;
      border-bottom: $pink solid 4px !important;
    }
  }

  .navBg {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 1430px) {
  header {
    .navbar {
      .nav-link {
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 4px !important;
        margin-right: 4px !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  header {
    .navbar {
      .nav-link {
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  header {
    .navbar {
      .nav-link {
        padding-top: 12px;
        padding-bottom: 12px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        border-bottom: gray solid thin;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  header {
    .navbar {
      img {
        width: 150px !important;
      }

      .navbar-toggler {
        line-height: 1;
        margin-bottom: 10px;
      }

      .nav-link {
        padding-bottom: 10px;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  header {
    .navbar {
      padding: 0.5rem 1rem 0 1rem;
    }

    .ml-auto {
      height: 200px;
      max-height: 200px;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; // mobile safari
    }
  }
}