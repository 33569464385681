* {
  .cityButtons {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: row;
    position: relative;
    div {
      width: 160px;
      height: 40px;
      text-align: center;
      padding-top: 5px;
    }
    .lineBreak1 {
      flex-basis: 100%;
      height: 0;
      display: none;
    }
    .lineBreak2 {
      flex-basis: 100%;
      height: 0;
      display: none;
    }
    .lineBreak3 {
      flex-basis: 100%;
      height: 0;
      display: none;
    }
    .leftBtn {
      border-radius: 5px 0 0 5px;
    }
    .midBtn {
      border-radius: 0px;
      border-left: 1px solid $lightgrey;
    }
    .rightBtn {
      border-radius: 0 5px 5px 0;
      border-left: 1px solid $lightgrey;
    }
    .pinkBtn {
      background-color: $pink;
    }
    .blueBtn {
      background-color: $blue;
    }
    .greyBtn {
      background-color: grey;
    }
    .greyBtn:hover {
      color: white;
      background-color: $pink; // We all decided to let the hover over a btn be of color pink
      cursor: pointer;
    }

    .all:active,
    .aarhus:active,
    .kbh:active,
    .odense:active,
    .aalborg:active,
    .other:active {
      color: white;
      background-color: $pink;
      cursor: pointer;
    }
  }
}
