@import "../../Reusable/colors";
@import "../../Reusable/cityBtns";

.findParkingList {
  width: 100%;
  background: white;

  .findParkeringListComponent {
    margin: auto auto 70px;
    width: 100%;

    .typeParking {
      margin-bottom: 15px;

      .typeParkingLineBreak {
        display: none;
      }

      button {
        width: 160px;
        height: 40px;
        color: white;
        font-size: 20px;
        font-weight: 600;
        border: none;
      }
      #btnMonthlyParking {
        background-color: $pink;
        border-radius: 5px 0px 0px 5px;
      }
      #btnTimeParking {
        background-color: #c4c4c4;
        border-radius: 0px 5px 5px 0px;
        pointer-events: none;
      }
      .btnTimeParkingWrapper {
        display: inline-block;
      }
    }

    .noParkeringContainer {
      // max-width: 1200px;
      // width: 1200px;
      margin: auto;

      p {
        word-break: break-word;
        white-space: normal;
      }
      .text {
        width: 50%;
      }
      button {
        margin-top: 50px;
      }
      .images {
        display: flex;
        align-items: flex-end;
        width: fit-content;
        margin-right: 0;
        margin-left: auto;
        margin-top: -160px;
        img {
          width: 120px;
          margin: 0 10px;
        }
        .house {
          margin-bottom: 20px;
          width: 190px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .findParkingList {
    .findParkeringListComponent {
      width: 90vw;
    }
    /* .noParkeringContainer {
      // max-width: 90vw;
      width: 90vw;
      .text {
        width: 70%;
      }
    } */
  }
}

@media only screen and (max-width: 1083px) {
  .findParkingList {
    .findParkeringListComponent {
      .noParkeringContainer {
        .text {
          width: 100%;
        }
        .images {
          margin: 0 auto;
          img {
            width: 15vw;
          }
          .house {
            width: 20vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 870px) {
  .findParkingList {
    .findParkeringListComponent {
      .cityButtons {
        flex-wrap: wrap;
        .lineBreak2 {
          display: block;
        }
      }

      .noParkeringContainer {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 710px) {
  .findParkingList {
    .findParkeringListComponent {
      .noParkeringContainer {
        .images {
          margin-top: 20px;
          img {
            margin: 5px;
          }
          .house {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 645px) {
    .findParkingList {
      .findParkeringListComponent {
        .cityButtons {
          .kbh {
            border-radius: 0 5px 5px 0;
          }
          .aalborg {
            border-radius: 5px 0 0 5px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 540px) {
    .findParkingList {
      .findParkeringListComponent {
        .cityButtons {
          .aarhus {
            border-radius: 0 5px 5px 0;
          }
          .kbh {
            border-radius: 5px 0 0 5px;
          }
          .aalborg {
            border-radius: 0 5px 5px 0;
          }
          .odense {
            border-radius: 5px 0 0 5px;
          }
          .lineBreak1 {
            display: block;
          }
          .lineBreak2 {
            display: none;
          }
          .lineBreak3 {
            display: block;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 370px) {
    .findParkingList {
      .findParkeringListComponent {
        .typeParking {
          .typeParkingLineBreak {
            display: block;
          }
        }
        .cityButtons {
          .all,
          .aarhus,
          .kbh,
          .odense,
          .aalborg,
          .other {
            border-radius: 0;
          }
          .lineBreak1 {
            display: none;
          }
          .lineBreak3 {
            display: none;
          }
          .midBtn {
            border-bottom: 1px solid $lightgrey;
          }
        }
      }
    }
  }
}
