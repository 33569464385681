@import "../Reusable/fontsizes";
@import "../Reusable/colors";
@import "../Reusable/btns";

.frontpage {
  .content {
    width: 1200px;
    margin: auto;
  }

  /* TOP SECTION CODE */
  .top {
    height: 100vh;
    background-image: url("../../Assets/Frontpage/cars-parked-in-aarhus.JPG");
    background-position: center 50%;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;

    .bg {
      height: 100vh;
      width: 100%;
      position: relative;
      background: rgb(0, 99, 219);
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 35vh;

        .element1 {
          text-align: center;

          .logo {
            width: 60%;
            padding-bottom: 10px;
          }

          .buttonContainer {
            text-align: center;
            margin: 18vh auto 0;
            width: 460px;
            display: flex;
            justify-content: space-between;

            .findPButton {
              background-color: $blue !important;
              font-weight: 800;
              width: 205px;
            }

            .rentOutButton {
              background-color: $pink !important;
              font-weight: 800;
              width: 205px;
            }

            /* Buzz Out */
            @-webkit-keyframes hvr-buzz-out {
              10% {
                -webkit-transform: translateX(3px) rotate(2deg);
                transform: translateX(3px) rotate(2deg);
              }

              20% {
                -webkit-transform: translateX(-3px) rotate(-2deg);
                transform: translateX(-3px) rotate(-2deg);
              }

              30% {
                -webkit-transform: translateX(3px) rotate(2deg);
                transform: translateX(3px) rotate(2deg);
              }

              40% {
                -webkit-transform: translateX(-3px) rotate(-2deg);
                transform: translateX(-3px) rotate(-2deg);
              }

              50% {
                -webkit-transform: translateX(2px) rotate(1deg);
                transform: translateX(2px) rotate(1deg);
              }

              60% {
                -webkit-transform: translateX(-2px) rotate(-1deg);
                transform: translateX(-2px) rotate(-1deg);
              }

              70% {
                -webkit-transform: translateX(2px) rotate(1deg);
                transform: translateX(2px) rotate(1deg);
              }

              80% {
                -webkit-transform: translateX(-2px) rotate(-1deg);
                transform: translateX(-2px) rotate(-1deg);
              }

              90% {
                -webkit-transform: translateX(1px) rotate(0);
                transform: translateX(1px) rotate(0);
              }

              100% {
                -webkit-transform: translateX(-1px) rotate(0);
                transform: translateX(-1px) rotate(0);
              }
            }

            @keyframes hvr-buzz-out {
              10% {
                -webkit-transform: translateX(3px) rotate(2deg);
                transform: translateX(3px) rotate(2deg);
              }

              20% {
                -webkit-transform: translateX(-3px) rotate(-2deg);
                transform: translateX(-3px) rotate(-2deg);
              }

              30% {
                -webkit-transform: translateX(3px) rotate(2deg);
                transform: translateX(3px) rotate(2deg);
              }

              40% {
                -webkit-transform: translateX(-3px) rotate(-2deg);
                transform: translateX(-3px) rotate(-2deg);
              }

              50% {
                -webkit-transform: translateX(2px) rotate(1deg);
                transform: translateX(2px) rotate(1deg);
              }

              60% {
                -webkit-transform: translateX(-2px) rotate(-1deg);
                transform: translateX(-2px) rotate(-1deg);
              }

              70% {
                -webkit-transform: translateX(2px) rotate(1deg);
                transform: translateX(2px) rotate(1deg);
              }

              80% {
                -webkit-transform: translateX(-2px) rotate(-1deg);
                transform: translateX(-2px) rotate(-1deg);
              }

              90% {
                -webkit-transform: translateX(1px) rotate(0);
                transform: translateX(1px) rotate(0);
              }

              100% {
                -webkit-transform: translateX(-1px) rotate(0);
                transform: translateX(-1px) rotate(0);
              }
            }

            .hvr-buzz-out {
              display: inline-block;
              vertical-align: middle;
              -webkit-transform: perspective(1px) translateZ(0);
              transform: perspective(1px) translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .hvr-buzz-out:hover,
            .hvr-buzz-out:focus,
            .hvr-buzz-out:active {
              -webkit-animation-name: hvr-buzz-out;
              animation-name: hvr-buzz-out;
              -webkit-animation-duration: 0.75s;
              animation-duration: 0.75s;
              -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
              -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
            }

            /* Glow */
            .hvr-glow {
              display: inline-block;
              vertical-align: middle;
              -webkit-transform: perspective(1px) translateZ(0);
              transform: perspective(1px) translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: box-shadow;
              transition-property: box-shadow;
            }

            .hvr-glow:hover,
            .hvr-glow:focus,
            .hvr-glow:active {
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
            }
          }
        }

        .btn-primary,
        .btn-primary:hover {
          border: none !important;
        }
      }
    }
  }

  /* CONCEPT CODE */
  .concept {
    text-align: left;

    .content {
      text-align: left;

      .card {
        border: none !important;

        .card-body {
          padding: 0;
        }
      }
    }

    .elementFlex {
      display: flex;
    }

    .elementLeft {
      box-sizing: border-box;
      padding-right: 60px;
      margin-top: -15px;
    }

    .paragraphHeading {
      text-align: center;
    }

    .element1 {
      display: flex;
      align-items: center;

      .conceptText {
        padding-left: 20px;

        .paragraph {
          padding-bottom: 20px;
        }
      }

      img {
        height: 155px;
        width: auto;
        flex: 1;
        margin: 20px;
        margin-left: 15px;
      }
    }

    .element2 {
      display: flex;
      margin-top: 0px;
      align-items: center;

      .conceptText {
        padding-left: 20px;
      }

      img {
        width: 130px;
        margin-right: 10px;
      }

      span {
        font-size: 22px;
        font-weight: 700;
        display: flex;
        justify-content: center;
      }
    }

    .element3 {
      margin: auto;

      iframe {
        border: none;
      }
    }
  }

  /* WHY PARKSHARE CODE */
  .whyParkshare {
    background-color: $blue;
    color: white;
    text-align: center;

    .content {
      .whyContainer {
        display: flex;

        .element {
          width: 21%;
          margin: auto;
        }

        img {
          width: auto;
          height: 120px;
          margin-bottom: 30px;
        }
      }
    }
  }

  /* Animation */
  .animationContent {
    img {
      width: 1200px;
      height: auto;
    }
  }

  /* REVIEW new */
  .reviewContent {
    background-image: url("../../Assets/Frontpage/man-renting-out-parking-infront-of-house.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    z-index: -2;

    .bg {
      height: 100%;
      background: rgb(0, 99, 219);
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);
      z-index: -1;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        img {
          position: absolute;
          width: 4vw;
        }

        .reviewLeftImg {
          top: 10%;
          left: 0;
        }

        .reviewRightImg {
          bottom: 10%;
          right: 0;
        }
      }
    }
  }
}

/*Brands*/
.brands {
  .contentBrand {
    width: 1200px;
    margin: 0;
  }
}

@media only screen and (max-width: 1280px) {
  .frontpage {
    .content {
      width: 80%;
    }

    .top {
      .bg {
        .content {
          .element1 {
            margin: auto;
            padding-right: 0px;
            padding-top: 0px;
            width: 100vw;
            margin-bottom: 50px;

            .buttonContainer {
              flex-direction: column;
              justify-content: center;
              width: 225px;

              .findPButton {
                width: 100%;
              }

              .rentOutButton {
                margin-top: 5%;
                width: 100%;
              }

              .hvr-buzz-out {
                display: inline-block;
                vertical-align: middle;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0;
              }

              .hvr-buzz-out:hover,
              .hvr-buzz-out:focus,
              .hvr-buzz-out:active {
                -webkit-animation-name: none;
                animation-name: none;
              }

              .hvr-glow {
                display: inline-block;
                vertical-align: middle;
                -webkit-transform: none;
                transform: none;
                box-shadow: none;
              }

              .hvr-glow:hover,
              .hvr-glow:focus,
              .hvr-glow:active {
                box-shadow: 0;
              }
            }
          }

          .element2 {
            width: 80vw;
          }
        }
      }
    }

    .concept {
      .content {
        .elementFlex {
          display: block;
        }

        .element3 {
          padding: 50px 0 0 0;
          text-align: center;
        }
      }
    }

    .whyParkshare {
      .content {
        height: auto;

        .whyContainer {
          flex-direction: column;
          justify-content: center;
          align-content: center !important;
          text-align: center;

          .element {
            width: 50%;
            margin: 3% auto;
          }

          div {
            width: 50vw;
            flex: none;
            margin: auto;
            margin-bottom: 500px;
          }
        }
      }
    }

    .animationContent {
      text-align: center;
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .frontpage {
    .concept {
      .content {
        margin-bottom: 30px;
        height: auto;

        .conceptCard {
          margin-top: 0;
        }

        .elementLeft {
          padding: 0;
          margin: 0;

          .conceptText {
            padding: 0;
          }

          .element1 {
            flex-direction: column;
            margin-bottom: 50px;
            margin-top: 30px;
            text-align: center;

            img {
              width: 100px;
              margin: auto;
            }
          }

          .element2 {
            flex-direction: column;
            text-align: center;

            img {
              width: 120px;
              margin: auto;
            }
          }
        }

        .element3 {
          iframe {
            width: 100%;
          }
        }
      }
    }

    .whyParkshare {
      .content {
        padding-top: 0px;
        height: auto;

        .whyContainer {
          flex-direction: column;
          align-content: center !important;
          text-align: center;
          margin: 0;

          div {
            width: 50vw;
            flex: none;
            margin: auto;
            margin-bottom: 100px;
          }

          .element {
            width: 90%;
          }
        }
      }
    }
  }

  .seenUs {
    .content {
      .seenUsContainer {
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .frontpage {
    .top {
      .bg {
        .content {
          .element1 {
            .logo {
              width: 100%;
            }

            .buttonContainer {
              width: 80%;
            }
          }
        }
      }
    }

    .animationContent {
      .content {
        width: 90% !important;
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .top {
    .element1 {
      margin-bottom: 0px !important;
    }
  }

  .reviewContent {
    .reviewLeftImg {
      left: -5% !important;
    }

    .reviewRightImg {
      right: -5% !important;
    }

    .reviewCarousel {
      top: 5%;

      p {
        font-size: 18px;
      }
    }
  }
}