@import "../Reusable/colors";

.privacy-policy {
  // display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;

  .li {
    margin: 10px 0;

    img {
      height: 20px;
      margin-right: 10px;
    }
  }

  .privacy-header {
    font-size: 48px;
    font-weight: 700;
  }

  .text-section {
    width: 75%;
    margin: auto;
    font-size: 18px;
    font-weight: 400;
  }

  .section-header {
    font-size: 36px;
    font-weight: 500;
    margin-top: 1rem !important;
  }

  .section-sub-header {
    font-size: 22px;
    font-weight: 700;
    margin-top: 1rem !important;
  }

  .section-sub-sub-header {
    font-size: 20px;
    font-weight: 600;
  }
}