@use 'mediaqueries';

// material-ui
.MuiFormGroup-row {
  justify-content: center;
}

.MuiTypography-body1 {
  font-size: 16px !important;
  letter-spacing: normal !important;
  line-height: normal !important;
}

.MuiButtonBase-root {
  padding: 4px !important;
}

.MuiFormControlLabel-root {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 4px !important;
  margin-top: -1px !important;
}

// base
.cookie-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0px;
  $button-margin: 10px;
  $button-width: 135px;

  .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    z-index: 999;
    border-radius: 15px;
    background-color: white;
    padding: 2px 6px;
    margin-bottom: 0px;

    .cookie-image-closed {
      width: 175px;
      height: 125px;
    }

    .cookie-image-open {
      width: 175px;
      height: 195px;
    }

    .cookie-content {
      margin-top: 2px;
      width: fit-content;

      .cookie-text-container {
        text-align: left;
        margin-top: 2px;
        padding: 4px;

        .cookie-header {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: -2px;
        }

        .cookie-text {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 2px;
        }
      }

      .cookie-button-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-top: 3px;
        $button-border-radius: 5px;
        $button-font-size: 16px;
        $button-font-weight: 400;
        $button-text-color: white;
        $button-padding: 2px;
        $button-height: 26px;

        .cookie-accept-button {
          border: 0px;
          border-radius: $button-border-radius;
          font-size: $button-font-size;
          font-weight: $button-font-weight;
          line-height: 50%;
          color: $button-text-color;
          padding: $button-padding;
          margin-left: $button-margin;
          width: $button-width;
          height: $button-height;
          background-color: #ff0084;
        }

        .cookie-decline-button {
          border: 0px;
          border-radius: $button-border-radius;
          font-size: $button-font-size;
          font-weight: $button-font-weight;
          line-height: 50%;
          color: $button-text-color;
          padding: $button-padding;
          margin-right: $button-margin;
          width: $button-width;
          height: $button-height;
          background-color: #858585;
        }
      }

      .cookie-control-container {
        padding-top: 9px;
        padding-bottom: 8px;

        .cookie-expand-controls {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: baseline;
          cursor: default;

          .cookie-expand-button {
            width: 15px;
            height: 10px;
            margin: 0px 4px;
          }

          .cookie-expand-button-reverse {
            width: 15px;
            height: 10px;
            transform: rotate(180deg);
            margin: 0px 4px;
          }

          .cookie-expand-text {
            font-size: 16px;
            font-weight: 400;
            margin: 2px 0px;
          }
        }
      }
    }

    .cookie-expand-container {
      margin-top: -4px;

      .cookie-expand-content {
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-direction: row;
        margin-bottom: 2px;
        margin-top: 4px;

        .cookie-expand-privacy-policy {
          font-size: 14px;
          width: $button-width;
          margin-right: $button-margin;
          text-align: right;
          color: #858585;
        }

        .cookie-expand-confirm-button {
          border: 0px;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          padding: 2px;
          margin-left: $button-margin;
          width: $button-width;
          background-color: #0061db;
        }
      }
    }
  }
}

// Minimized
.cookie-options {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
  z-index: 999;
  border-radius: 5px;
  padding: 8px;
  z-index: 999;
  margin-bottom: -20px;

  .cookie-options-col {
    display: flex;
    justify-content: center;
  }

  .cookie-options-image {
    width: 32px;
    height: 36px;
  }

  .cookie-options-text {
    color: rgba(255, 255, 255, 0.192);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    cursor: default;
    margin-bottom: 0px;
    align-items: center;
  }

  .cookie-options-text:hover {
    text-decoration: underline;
  }

  .terms-text {
    color: rgba(255, 255, 255, 0.192);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    cursor: default;
    margin-bottom: 0px;
    align-items: center;
  }
}

@keyframes moveOut {
  from {
    bottom: 0px;
    left: 0px;
    width: 560px;
    height: 135;
  }

  to {
    bottom: 6px;
    left: 6px;
    width: 32px;
    height: 36px;
  }
}

// Transition classes
.hidden {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .cookie-bar {
    width: 100%;

    .cookie-container {
      .cookie-image {
        display: none;
      }
    }
  }
}