@import "../../Reusable/colors";
@import "../../Reusable/cityBtns";

.eventList {
  width: 100%;
  background: $blue;
  $base: 0.6rem;

  // Which event the user clicked on
  .chosenOne {
    box-shadow: 0px 0px 0px 4px #ff0084;
  }

  .loading {
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    padding-top: 30px;
  }

  .card-container {
    // BG Color on card top  //hexcode-exention B3 = 70% visibility (this only goes for Browsers)
    .colorMusic {
      background-image: linear-gradient(
          135deg,
          rgba(0, 99, 219, 0.41),
          rgba(0, 99, 219, 0.41)
        ),
        url(../../../Assets/Event/music-concert-people.JPG);
      background-size: cover;
      background-position: bottom left;
    }
    .colorFootball {
      background-image: linear-gradient(
          135deg,
          rgba(91, 205, 89, 0.39),
          rgba(91, 205, 89, 0.39)
        ),
        url(../../../Assets/Event/football-event-game-match.jpg);
      background-size: 120%;
      background-position: 90% 30%;
    }
    .colorOther {
      background: linear-gradient(
          135deg,
          rgba(255, 0, 132, 0.39),
          rgba(255, 0, 132, 0.39)
        ),
        url(../../../Assets/Event/party-ornaments-colorful.jpeg);
      background-size: cover;
      background-position: center;
    }

    .card {
      width: 90%;
      margin: auto;
      margin-top: 30px;
      height: 300px;
      margin-bottom: 4px;
    }

    .cardTop {
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 3px 3px 0 0;
      border: 0;
      // FOOTBALL OR MUSIC OR OTHER ICON ON CARD TOP
      .iconContainer {
        background-color: white;
        height: 70px;
        width: 70px;
        border-radius: 100%;
        border: solid 2px black;
        margin: auto;
        display: block;
        text-align: center;
        .concertIcon {
          width: 80%;
          display: block;
          margin: auto;
          padding-top: 9px;
        }
        .football {
          width: 70%;
          display: block;
          margin: auto;
          padding-top: 10px;
          padding-left: 1px;
        }
        .balloon {
          width: 60%;
          display: block;
          margin: auto;
          padding-top: 13px;
        }
      }
      .title {
        width: 100%;
      }
    }

    .card-body {
      height: auto !important;
      width: 100% !important;
      color: black;
      padding: 15px 10px;
    }
    .card-title {
      font-size: 20px;
    }
    .card-subtitle {
      font-size: 12px;
    }
    .card-text {
      font-size: 14px;
    }
    .card-footer {
      padding: 5px 10px 8px 10px;
      font-size: 14px !important;
    }
  }

  .slider-container {
    width: 1050px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
    padding-bottom: 50px;

    .slick-next:before {
      color: $pink;
      font-size: 50px;
    }
    .slick-prev:before {
      color: $pink;
      font-size: 50px;
    }
    .slick-prev {
      left: -60px;
      width: 45px;
      height: 60px;
    }
    .slick-next {
      width: 45px;
      right: -58px;
      height: 60px;
      // top: 40% !important;
    }
    .slick-dots li button:before {
      color: white;
      font-size: 16px;
      margin-top: 10px;
    }
    .slick-prev:before {
      // background: url(../../Assets/Event/chevron-left.svg) center no-repeat;
      font-family: "FontAwesome";
      content: url(../../../Assets/Event/chevron-left.svg);
      color: $pink;
    }
    .slick-next:before {
      font-family: "FontAwesome";
      content: url(../../../Assets/Event/chevron-right.svg);
    }
  }

  .cityButtons {
    margin: auto;
    width: 1050px;
    padding-left: 10px;
    margin-top: 20px;

    .midBtn {
      border-left: 1px solid #8e8e8e;
    }
    .rightBtn {
      border-left: 1px solid #8e8e8e;
    }

    .greyBtn {
      background-color: #c4c4c4;
    }
    .greyBtn:hover {
      pointer-events: none;
    }
  }

  .swipe {
    display: none;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: white;
    margin-bottom: 0px;
  }

  .eventListBottomText {
    color: white;
    font-size: 24px;
    text-align: center;
    margin-bottom: 0;
    .eventArrowText {
      color: white;
      font-size: 24px;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .eventParkingListContainer {
    background-color: white;

    .soldOutText {
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      // padding: 55px 0px 70px 0px;
      padding: 60px 0px;
    }

    .noParkeringContainer {
      width: 1020px;
      margin: auto;
      .text {
        width: 50%;
      }
      button {
        margin-top: 50px;
      }
      .images {
        display: flex;
        align-items: flex-end;
        width: fit-content;
        margin-right: 0;
        margin-left: auto;
        margin-top: -160px;
        img {
          width: 120px;
          margin: 0 10px;
        }
        .house {
          margin-bottom: 20px;
          width: 190px;
        }
      }
    }
  }

  .flexContainer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    //SCROLL NED PIL
    .filler {
      width: 35px;
    }
    .arrowPlacer1 {
      flex: 1;
      height: 50px;
      display: flex;
      width: auto;
      justify-content: flex-end;
      margin-top: -55px;
    }
    .arrowContainer1 {
      width: auto;
      height: auto;
      margin-right: 15px;
    }
    .chevron {
      position: absolute;
      width: $base * 4;
      height: $base * 1;
      opacity: 0;
      transform: scale(0.3);
      animation: move-chevron 3s ease-out 0s infinite;
    }
    .chevron:first-child {
      animation: move-chevron 3s ease-out 1s infinite;
    }
    .chevron:nth-child(2) {
      animation: move-chevron 3s ease-out 2s infinite;
    }
    .chevron:before,
    .chevron:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 50%;
      background: $pink;
    }
    .chevron:before {
      left: 0;
      transform: skewY(30deg);
    }
    .chevron:after {
      right: 0;
      width: 50%;
      transform: skewY(-30deg);
    }
    @keyframes move-chevron {
      25% {
        opacity: 1;
      }
      33.3% {
        opacity: 1;
        transform: translateY($base * 3.8);
      }
      66.6% {
        opacity: 1;
        transform: translateY($base * 5.2);
      }
      100% {
        opacity: 0;
        transform: translateY($base * 8) scale(0.5);
      }
    }
    .arrowPlacer2 {
      flex: 1;
      height: 50px;
    }
    .arrowContainer2 {
      width: 100%;
      height: 0px;
      margin-top: -30px;
      margin-left: 15px;
    }
    .chevron2 {
      position: absolute;
      width: $base * 4;
      height: $base * 1;
      opacity: 0;
      transform: scale(0.3);
      animation: move-chevron2 3s ease-out 0s infinite;
    }
    .chevron2:first-child {
      animation: move-chevron2 3s ease-out 1s infinite;
    }
    .chevron2:nth-child(2) {
      animation: move-chevron2 3s ease-out 2s infinite;
    }
    .chevron2:before,
    .chevron2:after {
      content: "";
      position: absolute;
      top: 0px;
      height: 100%;
      width: 50%;
      background: $pink;
    }
    .chevron2:before {
      left: 0;
      transform: skewY(30deg);
    }
    .chevron2:after {
      right: 0;
      width: 50%;
      transform: skewY(-30deg);
    }

    @keyframes move-chevron2 {
      25% {
        opacity: 1;
      }
      33.3% {
        opacity: 1;
        transform: translateY($base * 3.8);
      }
      66.6% {
        opacity: 1;
        transform: translateY($base * 5.2);
      }
      100% {
        opacity: 0;
        transform: translateY($base * 8) scale(0.5);
      }
    }
  }
}

//Media Query
@media only screen and (max-width: 1280px) {
  .eventList {
    .cityButtons,
    .slider-container {
      width: 900px;
    }

    .card-container {
      .card-title {
        font-size: 20px;
      }
      .card-text {
        font-size: 16px;
      }
    }

    .eventListBottom {
      margin-top: 18px;
    }

    .eventParkingListContainer .noParkeringContainer {
      max-width: 80vw;
      .text {
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .eventList {
    .cityButtons,
    .slider-container {
      width: 800px;
    }

    .card-container {
      .card-title {
        font-size: 16px;
      }
      .card-text {
        font-size: 14px;
      }
      .card-columns {
        column-count: 3;
      }
    }
    .eventParkingListContainer .noParkeringContainer {
      .text {
        width: 100%;
      }
      .images {
        margin: 0 auto;
        img {
          width: 15vw;
        }
        .house {
          width: 20vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .eventList {
    .cityButtons,
    .slider-container {
      width: 700px;
    }
  }
}

@media only screen and (max-width: 870px) {
  .eventList {
    .cityButtons,
    .slider-container {
      width: 600px;
    }

    .eventParkingListContainer .noParkeringContainer {
      text-align: center;
    }

    .flexContainer {
      .arrowPlacer1,
      .arrowPlacer2 {
        display: none;
      }
    }
    .eventListBottomText {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .eventList {
    /*  .city-buttons {
      width: 545px;
    }
    .slider-container {
      width: 550px;
    } */

    .cityButtons,
    .slider-container {
      width: 550px;
    }

    .card-container {
      .card-title {
        font-size: 20px;
        margin-bottom: 7px;
      }
      .card-text {
        font-size: 15px;
      }
      .card-columns {
        column-count: 2;
      }
    }
  }
}

@media only screen and (max-width: 710px) {
  .eventList {
    .cityButtons {
      width: 80%;
      margin: auto;
      justify-content: center;
      padding-left: 0;
    }
    .slider-container {
      width: 450px;
      margin-bottom: 0px;

      .slick-dots {
        bottom: -60px;
        display: none !important;
      }
    }
    .noParkeringContainer {
      .images {
        margin-top: 20px;
        img {
          margin: 5px;
        }
        .house {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .eventList {
    .cityButtons {
      width: 400px;
    }
    .slider-container {
      width: 300px;
    }

    .flexContainer .filler {
      display: none;
    }
  }
}

@media only screen and (max-width: 625px) {
  .eventList {
    padding-left: 0;
    padding-right: 0;
    .eventParkingList {
      width: 100vw;
    }
  }
}

@media only screen and (max-width: 530px) {
  .eventList {
    .cityButtons {
      width: 350px;
    }
    .slider-container {
      width: 270px;

      .slick-prev {
        left: -20px;
        width: 30px;
        height: 60px;
      }
      .slick-next {
        width: 30px;
        right: -20px;
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .eventList {
    .slider-container {
      .slick-prev:before,
      .slick-next:before,
      .slick-next {
        display: none !important;
      }

      .slick-dots {
        bottom: -90px;
      }
    }

    .cityButtons {
      width: 280px;
      .leftBtn {
        font-size: 16px;
        padding-top: 10px;
      }
      .midBtn {
        font-size: 16px;
        padding-top: 10px;
      }
      .rightBtn {
        font-size: 16px;
        padding-top: 10px;
      }
    }

    .card-container {
      .card {
        height: 300px;
      }
      .card-title {
        font-size: 16px !important;
      }
      .card-text {
        font-size: 14px !important;
      }
    }

    .eventListBottomText {
      display: none;
    }

    .swipe {
      display: block;
    }
  }
}

@media only screen and (max-width: 310px) {
  .eventList {
    .city-buttons {
      width: 260px;
    }
  }
}
