@import "../../Reusable/colors";
.rentConfirmCard {
    .pSpotCardContainer {
        display: flex;
        box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        width: 90%;
        height: auto;
        margin: auto;
        .item1 {
            width: 266.6666666667px;
            img {
                // object-fit: cover;
                border-radius: 4px 0 0 4px;
                height: 283px;
                width: 266.6666666667px;
            }
        }
        .item2 {
            padding: 10px;
            flex-direction: column;
            justify-content: space-between;
            display: flex;
            width: 100%;
            .item2-1 {
                .paragraphHeading {
                    text-align: left;
                }
                .address {
                    margin-bottom: 8px;
                }
                .lejeperiode {
                    margin-bottom: 3px !important;
                    .lineShift {
                        display: none;
                    }
                }
                .price {
                    margin-bottom: 3px !important;
                }
            }
            .item2-2 {
                position: relative;
                width: 100%;
                padding-left: 0px;
                border-left: none;
                .paragraphHeading {
                    margin-bottom: 8px;
                    margin-top: 20px;
                }
                .hideHeading {
                    display: none;
                }
                p {
                    margin-bottom: 0px;
                }
                .monthlyRentPeriodTypeTime {
                    .hours {
                        display: flex;
                        .box1 {
                            margin-right: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 825px) {
    .rentConfirmCard {
        .pSpotCardContainer {
            margin-bottom: 20px;
            height: auto;
            flex-direction: column;
            .item1 {
                width: 100%;
                height: calc(90vw / 1.333);
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                    padding: 18px;
                }
            }
            .item2 {
                padding: 0 20px;
                .item2-1 {
                    margin-bottom: 30px;
                    .address {
                        margin-bottom: 5px;
                    }
                    .lejeperiode {
                        margin-bottom: 3px;
                        .lineShift {
                            display: none;
                        }
                    }
                    .price {
                        margin-bottom: 3px;
                    }
                }
                .item2-2 {
                    margin-bottom: 18px;
                }
            }
        }
    }
}

@media only screen and (max-width: 490px) {
    .rentConfirmCard {
        .pSpotCardContainer {
            .item1 {
                width: 100%;
                height: calc(90vw / 1.333);
                padding: 0;
                img {
                    height: 95%;
                    width: 95%;
                    border-radius: 4px;
                    padding: 0;
                }
            }
            .item2 {
                padding: 0 10px;
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .paragraph {
        font-size: 16px;
    }
    .rentConfirmCard {
        .pSpotCardContainer {
            width: auto;
            .item1 {
                display: none
            }
            .item2 {
                .item2-1 {
                    margin-top: 20px
                }
                .item2-2 {
                    .monthlyRentPeriodTypeTime {
                        .hours {
                            .box1 {
                                margin-right: 5px;
                            }
                            .box1,
                            .box2 {
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-height: 500px) {
    .rentConfirmModal {
        .a {
            margin: 40px 0;
        }
    }
}