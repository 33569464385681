@import "../Reusable/colors";

.udlejparkering {
  .content {
    width: 1200px;
    margin: auto;
  }

  .container {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg,
        rgba(0, 99, 219, 0.5032387955182073) 0%,
        rgba(255, 0, 100, 0.500437675070028) 100%);
    position: relative;
    z-index: 2;
  }

  .top {
    margin-top: 58px;
    height: 93.8vh;
    background-image: url("../../Assets/Udlejparkering/house-parked-cars.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .bg {
      height: 100%;
      background: rgb(0, 99, 219);
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        height: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        margin: auto;
        align-items: center;

        .element1 {
          .topButton {
            background-color: $pink;
          }
        }

        .element2 {
          iframe {
            border: none;
            height: 338px;
            width: 600px;
          }
        }
      }
    }
  }

  .mobileVideo {
    display: none;
  }

  //"Hvornår kan man udleje en parkeringsplads" section
  .whenCanYouRent {
    .shadowBox {
      border: 0.1px solid #e0e0e0;
      border-radius: 20px;
      box-shadow: 3px 3px 6px 3px #e0e0e0;
      width: 90%;
      margin: auto;

      .content {
        display: flex;
        justify-content: space-between;
        width: 68%;

        .leftElement {
          width: 40%;
        }

        .iconAndText {
          display: flex;
          flex-direction: column;
          width: 50%;
          justify-content: space-between;

          .rightElements {
            display: flex;
            margin-bottom: 30px;

            #decisionIcon {
              width: 90px;
              padding-right: 10px;
            }

            #bookingIcon {
              width: 90px;
              height: 50px;
            }

            #houseParkingIcon {
              width: 90px;
              padding-right: 10px;
            }

            p {
              display: flex;
              align-items: center;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  /* "Hvem kan udleje en parkeringsplads" section */
  .whocanrent {
    .content {
      .box {
        text-align: center;

        .container {
          margin-top: 50px;
          display: flex;

          .element1,
          .element2,
          .element3 {
            width: 100%;

            .iconParagraph {
              border: 2px solid white;

              a {
                color: black;
                font-weight: 700;
                text-decoration: underline;
              }
            }

            .fadingBackground {
              border: solid 4px;
              border-image: linear-gradient(white, $pink) 1 40;
              margin-bottom: 20px;
              padding: 20px 0;

              img {
                width: 90%;
                box-shadow: 7px 7px 6px rgba(0, 0, 0, 0.4);
              }
            }
          }

          .element1,
          .element2 {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .rentoutinfo {
    max-width: 100vw;

    .content {
      width: 1300px;
      height: 430px;
      background: white;
      border: 3px solid #d8d8d8;
      box-shadow: 0px 4px 8px 6px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;

      .box2 {
        margin-bottom: 100px;

        .subContainer {
          display: flex;
          width: 100%;

          .element1 {
            flex: 1;
            margin-left: 90px;
            line-height: 25px;
          }

          .element2 {
            flex: 1;
            width: 100px;
            margin-left: 110px;

            .subElement1 {
              display: flex;
              margin-bottom: 30px;

              img {
                width: 50px;
                margin-right: 20px;
              }

              p {
                font-size: 18px;
                font-weight: 600;
                align-self: center;
              }
            }

            .subElement2 {
              display: flex;
              margin-bottom: 30px;

              img {
                width: 50px;
                margin-right: 19px;
                height: 36.5px;
              }

              p {
                font-size: 18px;
                font-weight: 600;
                align-self: center;
              }
            }

            .subElement3 {
              display: flex;
              margin-left: 0px;

              img {
                width: 55px;
                margin-right: 15px;
              }

              p {
                font-size: 18px;
                font-weight: 600;
                align-self: center;
              }
            }
          }
        }
      }
    }
  }

  /* "Hvilke type parkering kan man udleje?" section */
  .pspotstyper {
    .content {
      .box {
        text-align: center;

        .container {
          display: flex;

          .element1,
          .element2,
          .element3 {
            width: 100%;

            .fadingBackground {
              background: linear-gradient(180deg, white, $blue);
              margin-bottom: 20px;
              padding-bottom: 4px;

              img {
                background: white;
                width: 97%;
                padding: 13px 42px;
              }
            }
          }

          .element1,
          .element2 {
            margin-right: 40px;
          }

          .element3 {
            .wrapping {
              display: flex;
              justify-content: center;
              align-items: center;

              .overlappingMessage {
                position: absolute;
                z-index: 2;
                padding: 15px;
                border-radius: 18px;
                background: rgba(196, 196, 196, 0.6);
                display: flex;
                align-items: center;

                p {
                  font-weight: 900;
                  font-size: 40px;
                  margin-bottom: 0;
                  color: #ff0084;
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  line-height: initial;
                }
              }

              .fadingBackground {
                position: relative;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

  /* "Hvilke type parkering kan man udleje?" section */
  .pris {
    height: auto;

    .content {
      .container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;

        .element1 {
          flex: 15;
          margin-right: 10px;
          border: 0px solid red;

          .paragraph {
            margin-bottom: 2vh !important;
          }
        }

        .element2 {
          flex: 9;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .indtjening {
    background-color: $blue;
    color: white;

    .content {
      display: flex;

      .element1 {
        width: 50vw;
        position: relative;

        img {
          width: 100%;
          height: 500px;
          object-fit: cover;
        }

        .bg {
          height: 100%;
          width: 100%;
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .element2 {
        border: 2px solid red;
        margin-left: 50px;

        .bold {
          font-size: 22px;
          font-weight: 700;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .earnings {
    margin: 0;

    .content {
      width: 100%;
      display: flex;
      background-color: $blue;
      color: white;
    }

    .earnings-image {
      position: relative;

      img {
        width: 50vw;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgb(0, 99, 219);
        background: linear-gradient(0deg,
            rgba(0, 99, 219, 0.5032387955182073) 0%,
            rgba(255, 0, 100, 0.500437675070028) 100%);
      }
    }

    .earnings-details {
      padding-left: 50px;
      padding-right: 8%;

      h2 {
        text-align: left;
        margin-bottom: 1vh;
      }

      .earningsText {
        p {
          padding-bottom: 3vh;
        }

        .moreSpace {
          padding-top: 2vh;
        }

        .lastParagraph {
          padding-bottom: 0;
        }
      }
    }
  }

  // 20.000kr svarer til
  .infografik {
    background-color: $pink;
    color: white;
    justify-content: center;
    margin-top: auto;

    .content {
      .container {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .element {
          flex: 1;
          text-align: center;
          margin: 20px 20px 40px 20px;

          img {
            height: 90px;
            display: block;
            margin: 0 auto 20px;
          }
        }
      }
    }
  }

  //udlej en parkering
  .udlejPladser {
    height: auto;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  /* "Hvorfor udleje sin parkering" section */
  .whyrentout {
    .content {
      .container {
        display: flex;
        justify-content: space-between;

        .element1 {
          width: 60%;
        }

        .element2 {
          img {
            width: 400px;
          }
        }
      }
    }
  }

  /* "Hvilken type biler kan leje parkering" section */
  .carType {
    .content {
      .container {
        display: flex;
        justify-content: space-between;

        .element1 {
          width: 45%;

          .paragraph {
            p {
              margin-bottom: 0 !important;
            }
          }
        }

        .vertical-line {
          display: inline-block;
          border-left: 1px solid #c0c0c0;
        }

        .element2 {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .carTypeMobileOnly {
            display: none;
          }

          .check {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
              font-weight: 600;
              font-size: 29px !important;
              margin-left: 5%;
              line-height: 1.25;
              margin-bottom: 0;
            }

            .checkbox {
              height: 80px;
              width: auto;
            }

            .grayCheckbox {
              height: 75px;
              width: auto;
              margin-right: 11px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .udlejparkering {
    .content {
      width: 80%;
    }

    .top {
      height: 60vw;

      .bg {
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          .element1 {
            margin-bottom: 2vh;
            margin-right: 0;

            .topButton {
              display: initial;
            }
          }

          .element2 {
            iframe {
              height: 225px;
              width: 400px;
            }
          }
        }
      }
    }

    .earnings {
      .content {
        flex-direction: column;
      }

      .earnings-image {
        img {
          width: 100%;
          height: 60vw;
        }
      }

      .earnings-details {
        width: 100%;
      }
    }

    .infografik {
      .content {
        .container {
          .element {
            flex: none;
            width: 150px;
          }
        }
      }
    }

    .whenCanYouRent {
      .sectionHeading {
        width: 90% !important;
        margin: 0 auto 6vh;
      }

      .content {
        flex-direction: column;

        .leftElement {
          width: 100% !important;
        }

        .iconAndText {
          width: 100% !important;

          .rightElements {
            padding-top: 1vh;
            margin-top: 0 !important;
          }
        }
      }
    }

    .whyrentout {
      .content {
        .container {
          display: block;

          .element1 {
            width: 100%;
          }

          .element2 {
            text-align: center;

            img {
              width: 40vw;
              margin-top: 2vh;
            }
          }
        }
      }
    }

    .carType {
      .content {
        .container {
          height: auto;
          flex-direction: column;

          .element1 {
            width: 100%;
          }

          .vertical-line {
            display: none !important;
          }

          .element2 {
            padding: 0;
            width: 100%;

            .check {
              margin-top: 20px;
            }

            .grayCheckbox {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .pris {
      .content {
        .container {
          .element1 {
            margin-right: 0;
          }

          .element2 {
            width: 50%;
            margin: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .udlejparkering {
    .pris {
      .content {
        .container {
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .udlejparkering {
    .whocanrent {
      .content {
        .box {
          padding-bottom: 0px;
          padding-top: 0px;
          height: auto;

          .container {
            flex-direction: column;
            align-content: center !important;
            text-align: center;
            justify-content: center;

            .element1,
            .element2,
            .element3 {
              width: 50%;
              height: auto;
              margin: 3% auto;
              flex: none;
            }
          }
        }
      }
    }

    .pspotstyper {
      .content {
        .box {
          padding-bottom: 0px;
          padding-top: 0px;
          height: auto;

          .container {
            flex-direction: column;
            align-content: center !important;
            text-align: center;
            justify-content: center;

            .element1,
            .element2,
            .element3 {
              width: 50%;
              height: auto;
              margin: 3% auto;
              flex: none;
            }

            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 45px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .udlejparkering {
    .udlejPladser {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .udlejparkering {
    .top {
      .bg {
        .content {
          .element2 {
            iframe {
              height: 180px;
              width: 318px;
            }
          }
        }
      }
    }

    .whocanrent {
      .content {
        .box {
          .container {

            .element1,
            .element2,
            .element3 {
              width: 80%;

              .paragraph {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .pspotstyper {
      .content {
        .box {
          .container {
            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .udlejparkering {
    .whocanrent {
      .content {
        .box {
          .description {
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .udlejparkering {
    .top {
      .bg {
        .content {
          .element2 {
            display: none;
          }
        }
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;

      iframe {
        border-width: 0px;
      }
    }

    .carType {
      .content {
        .container {
          display: block;

          .element1 {
            display: none;
          }

          .vertical-line {
            display: none;
          }

          .element2 {
            margin: 0;
            width: 100%;

            .carTypeMobileOnly {
              display: block;
            }

            .check {
              p {
                font-size: 22px !important;
                line-height: 1.2;
              }

              .checkbox {
                height: 60px;
              }

              .grayCheckbox {
                height: 60px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .udlejparkering {
      .whenCanYouRent {
        .content {
          .rightElements {
            img {
              display: none;
            }

            p {
              display: block;
            }
          }
        }
      }

      .whocanrent {
        .content {
          .box {

            .element1,
            .element2,
            .element3 {
              width: 80%;
            }
          }
        }
      }

      .pspotstyper {
        .content {
          .box {
            .container {

              .element1,
              .element2,
              .element3 {
                width: 80%;
              }

              .element3 {
                .wrapping {
                  .overlappingMessage {
                    p {
                      font-size: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .udlejparkering {
      .udlejPladser {
        .desc {
          padding-left: 0;
          padding-right: 0;
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-height: 500px) {
    .udlejparkering {
      .top {
        height: 84.4vh !important;

        .bg {
          .content {
            display: flex;
            align-items: center;

            .element2 {
              display: none;
            }
          }
        }
      }

      .mobileVideo {
        display: flex;
        justify-content: center;
      }

      .whyrentout {
        .content {
          .container {
            .element2 {
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .udlejparkering {
      .pspotstyper {
        .content {
          .box {
            .container {
              .element3 {
                .wrapping {
                  .overlappingMessage {
                    p {
                      font-size: 35px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 335px) {
    .udlejparkering {
      .pspotstyper {
        .content {
          .box {
            .container {
              .element3 {
                .wrapping {
                  .overlappingMessage {
                    p {
                      font-size: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 300px) {
    .udlejparkering {
      .pspotstyper {
        .content {
          .box {
            .container {
              .element3 {
                .wrapping {
                  .overlappingMessage {
                    margin-bottom: 3%;
                    padding: 12px;

                    p {
                      font-size: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}