@import "../Reusable/colors";

.for-companies {
  .content {
    width: 1200px;
    margin: auto;
  }

  .top {
    margin-top: 58px;
    height: 93.8vh;
    background-image: url("../../Assets/Forbusiness/office-company-parking.jpeg");
    background-position: 100% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    .bg {
      height: 100%;
      background: rgb(0, 99, 219);
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        height: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        margin: auto;
        align-items: center;

        .element1 {
          .topButton {
            background-color: $pink;
          }
        }

        .element2 {
          iframe {
            border: none;
            height: 338px;
            width: 600px;
          }
        }
      }
    }
  }

  .mobileVideo {
    display: none;
  }

  .how-it-works {
    .content {
      .steps {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -10px;
      }

      .step {
        margin: 10px;
        max-width: 285px;
        padding: 20px 20px 10px 20px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;

        div {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          h4 {
            color: $pink;
            font-size: 115px;
            font-weight: 800;
            margin-right: 10px;
            line-height: 0.8;
          }
        }
      }
    }
  }

  .benefits {
    background-color: $blue;
    color: white;

    .content {
      .flex-container {
        margin: auto;
        display: flex;
        justify-content: space-between;

        span {
          height: inherit;
          margin: 0px 40px 20px;
          width: 1px;
          background-color: white;
          display: block;
        }

        .column {
          .benefit {
            display: flex;
            margin-bottom: 3vh;
            align-items: center;

            img {
              width: 100px;
              height: 100%;
            }

            div {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .parking-types {
    .content {
      p {
        margin-bottom: 0;
      }

      .types-container {
        margin: 0 -10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .type {
        text-align: center;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 30px;
        width: 385px;
        height: 450px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 200px;
        }
      }

      .coming-soon-type {
        position: relative;

        p {
          color: #858585;
        }

        h3 {
          color: #858585;
        }

        .bookmark {
          position: absolute;
          right: 30px;
          top: 0;

          .rectangle {
            width: 50px;
            height: 60px;
            background-color: $pink;
          }

          .triangle {
            position: absolute;
            top: 35px;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 25px solid $pink;
            border-right: 25px solid $pink;
          }
        }

        .coming-soon {
          color: $pink;
          position: absolute;
          font-weight: 700;
          right: 30px;
          top: -40px;
          font-size: 30px;
        }
      }
    }
  }

  .monthly {
    .content {
      max-width: 920px;
    }

    .intro {
      margin: auto;
      margin-bottom: 2vh;
      text-align: left;
    }

    .types-container {
      margin: auto;
    }

    .types {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin: -10px;
    }

    .type {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      padding: 30px;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      margin: 10px;
      align-items: center;

      img {
        height: 90px;
        margin-right: 20px;
      }

      .details {
        width: 70%;
      }

      .hours {
        display: flex;
        justify-content: space-between;
      }

      p {
        margin: 0;
      }
    }
  }

  .earnings {
    margin: 0;

    .content {
      width: 100%;
      display: flex;
      background-color: $blue;
      color: white;
    }

    .earnings-image {
      position: relative;

      img {
        width: 50vw;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgb(0, 99, 219);
        background: linear-gradient(0deg,
            rgba(0, 99, 219, 0.5032387955182073) 0%,
            rgba(255, 0, 100, 0.500437675070028) 100%);
      }
    }

    .earnings-details {
      padding-left: 50px;
      padding-right: 8%;

      h2 {
        text-align: left;
        margin-bottom: 1vh;
      }

      .earningsText {
        p {
          padding-bottom: 3vh;
        }

        .moreSpace {
          padding-top: 2vh;
        }

        .lastParagraph {
          padding-bottom: 0;
        }
      }
    }
  }

  .for-employees {
    background-color: $pink;
    color: white;

    .content {
      .items {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .item {
        flex: 1;
        text-align: center;
        margin: 20px 20px 30px 20px;

        img {
          height: 100px;
          display: block;
          margin: 0 auto 20px;
        }
      }
    }
  }

  .who-sets {
    .content {
      .grid {
        display: flex;
        margin: -10px;
      }

      .box {
        padding: 30px;
        background-color: #0063db;
        border-radius: 8px;
        margin: 10px;
        color: white;
        height: fit-content;

        p {
          margin-bottom: 0;
        }
      }

      .who {
        background-color: $pink;
        flex: 2;
      }

      .how {
        background-color: $blue;
        flex: 3;
        margin-top: 70px;
      }

      .why {
        background-color: #858585;
        flex: 3;
        margin-top: 140px;
      }

      img {
        height: 300px;
        margin-top: -250px;
      }
    }
  }

  .non-profits {
    background-color: $blue;
    color: white;
    text-align: center;

    .content {
      .description {
        width: 800px;
        margin: 0 auto 30px;

        span {
          height: 1px;
          margin: 40px 0;
          width: 100%;
          background-color: white;
          display: block;
        }

        .subHeading {
          font-weight: 700;
        }
      }

      .charities {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;

        a {
          &:hover {
            text-decoration: none;
          }
        }

        .charity {
          margin: 10px auto;
          background-color: white;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          height: 320px;
          width: 24%;

          img {
            width: 100%;
            height: 35%;
            object-fit: cover;
            border-radius: 8px 8px 0px 0px;
          }

          div {
            color: black;
            padding: 18px;
            height: 65%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            button {
              border: none;
              background-color: transparent;
              position: relative;
              padding: 0;
              width: fit-content;

              &::after {
                content: "";
                background-color: black;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
              }
            }
          }

          .kenya {
            object-position: 100% 10%;
          }

          .elephants {
            object-position: 100% 70%;
          }
        }
      }
    }
  }

  .rent-out {
    .description {
      text-align: center;
      margin-bottom: 4vh;

      .pink {
        color: $pink;
        font-size: 28px;
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .for-companies {
    .content {
      width: 80% !important;
      margin: auto;
    }

    .top {
      height: 60vw;

      .bg {
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          .element1 {
            margin-bottom: 2vh;
            margin-right: 0;

            h1 {
              text-align: center;
            }

            p {
              text-align: center;
            }

            .topButton {
              display: initial;
            }
          }

          .element2 {
            iframe {
              height: 225px;
              width: 400px;
            }
          }
        }
      }
    }

    .benefits {
      .content {
        .flex-container {
          width: auto;
          flex-direction: column;

          span {
            display: none;
          }
        }
      }
    }

    .monthly {
      .types {
        .type {
          flex-direction: column;

          h3 {
            text-align: center;
          }

          img {
            margin: 0;
            margin-bottom: 10px;
          }

          .details {
            width: 100%;
          }
        }
      }
    }

    .earnings {
      .content {
        width: 100% !important;
        flex-direction: column;
      }

      .earnings-image {
        img {
          width: 100%;
          height: 60vw;
        }
      }

      .earnings-details {
        width: 100%;
      }
    }

    .for-employees {
      .content {
        .item {
          flex: none;
          width: 150px;
        }
      }
    }

    .parking-types {
      .content {
        .type {
          img {
            width: 150px;
          }
        }

        .type:last-child {
          margin: 40px 10px;
        }
      }
    }

    .non-profits {
      .content {
        .description {
          width: auto;
        }

        .charities {
          .charity {
            width: 300px;
            height: 310px;
          }
        }
      }
    }

    .rent-out {
      .content {
        width: 90vw !important;
      }
    }

    .faq {
      .content {
        width: 80%;
      }

      .sectionHeading {
        margin-bottom: 3vh;
      }
    }
  }
}

@media only screen and (max-width: 865px) {
  .for-companies {
    .who-sets {
      .content {
        .grid {
          flex-direction: column;
        }

        .how {
          margin-top: 10px;
        }

        .why {
          margin-top: 10px;
        }

        img {
          display: none;
        }
      }
    }

    .non-profits {
      .content {
        .charities {
          flex-direction: column;
        }
      }
    }

    .non-profits {
      .content {
        .charities {
          .charity {
            height: auto;

            img {
              height: 120px;
            }

            div {
              height: auto;

              button {
                padding-top: 15px;
              }
            }
          }
        }
      }
    }

    .monthly {
      .types {
        display: flex;
        flex-direction: column;
      }

      .type {
        width: 350px;
        margin: 10px auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .for-companies {
    .top {
      .bg {
        .content {
          .element1 {
            p {
              display: none;
            }

            .topButton {
              margin-top: 0;
            }
          }

          .element2 {
            iframe {
              height: 180px;
              width: 318px;
            }
          }
        }
      }
    }

    .how-it-works {
      .content {
        width: auto;
      }
    }

    .parking-types {
      .content {
        .type {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .for-companies {
    .top {
      .bg {
        .element1 {
          margin-bottom: 0 !important;

          p {
            text-align: center;
          }
        }

        .element2 {
          display: none;
        }
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;

      iframe {
        border-width: 0px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .for-companies {
    .top {
      .bg {
        .content {
          .element1 {
            p {
              display: none;
            }
          }

          .element2 {
            display: none;
          }

          .topButton {
            margin-top: 0;
          }
        }
      }
    }

    .monthly {
      .content {
        max-width: auto;
        width: 90%;
      }

      .type {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .for-companies {
    .top {
      height: 84.4vh !important;

      .bg {
        .content {
          display: flex;
          align-items: center;

          .element2 {
            display: none;
          }
        }
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;
    }
  }
}