@import "../Reusable/colors";

.aboutus {
  .content {
    width: 1200px;
    margin: auto;
  }

  .container {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .top {
    margin-top: 58px;
    height: 93.8vh;
    background-image: url("../../Assets/Aboutus/office-whiteboard-employees.jpg");
    background-position: 50% 25%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;

    .bg {
      height: 100%;
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        position: relative;
        top: 15%;
      }
    }
  }

  // "Where it all started" section
  .whereitallstarted {
    .content {
      width: 900px;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .element1 {
          img {
            width: 250px;
            border-radius: 100%;
            box-shadow: -3px 10px 10px rgba(0, 0, 0, 0.3);
          }
        }

        .element2 {
          width: 60%;
        }
      }
    }
  }

  //  "Hvor stort er parkeringsproblemet i dk?" section
  .parkeringproblem {
    text-align: center;

    .content {
      .container {
        display: flex;

        img {
          width: 140px;
        }

        div {
          margin: 20px;

          p {
            margin-top: 1vh;
          }
        }
      }
    }
  }

  // "Hvordan løser ParkShare parkeringsproblemet? & Images" section
  .problemsolving {
    .content {
      margin: auto;

      .textContainer {
        margin: auto;
      }

      .imgContainer {
        text-align: center;
        width: 50%;
        margin: auto;

        .row1 {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 20px;

          div {
            width: 30%;

            img {
              width: 70%;
            }
          }
        }

        .row2 {
          width: 60%;
          text-align: center;
        }
      }
    }
  }

  // Mission and vision section
  .mission_vision {
    background-image: url("../../Assets/Aboutus/startup-talk-conference-woman.jpg");
    background-size: cover;
    background-position: center center;

    .content {
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);
      height: 93vh;
      width: 100%;
      position: relative;

      .missionContainer {
        position: absolute;
        left: 2vw;
        top: 1vw;
        width: 70%;
        color: white;

        .missionHeading {
          font-size: 5vw;
          font-weight: 700;
        }

        .missionText {
          font-size: 2.5vw;
          font-weight: 400;
        }
      }

      .visionContainer {
        position: absolute;
        bottom: 1vw;
        right: 2vw;
        color: white;
        width: 70%;
        text-align: right;

        .visionHeading {
          font-size: 5vw;
          font-weight: 700;
        }

        .visionText {
          font-size: 2.5vw;
          font-weight: 400;

          #smallNumber {
            font-size: 1.5vw;
          }
        }
      }
    }
  }

  // "Would you like to join the team" section
  .joinTeam {
    .content {
      width: 900px;

      .container {
        max-width: 100%;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .element1 {
          width: 60%;

          .paragraph {
            padding-bottom: 2vh;
          }

          .regular-button {
            justify-content: left;
            margin-top: 0;

            #btn-submit {
              background-color: $blue;
            }
          }
        }

        .element2 {
          img {
            width: 300px;
            box-shadow: 15px 16px 10px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .aboutus {
    .top {
      height: 60vw;
    }

    .content {
      width: 80% !important;
    }

    .whereitallstarted {
      .content {
        .container {
          flex-direction: column;

          .element1 {
            img {
              width: 35vw;
              margin-bottom: 2vh;
            }
          }

          .element2 {
            p {
              margin-top: 1vh;
            }
          }
        }
      }
    }

    .parkeringproblem {
      .content {
        .container {
          flex-wrap: wrap;

          div {
            flex: none;
            width: 50%;
            margin: 0;
          }

          p {
            margin-left: 2vw;
          }
        }
      }
    }

    .mission_vision {
      .content {
        width: 100% !important;

        .missionContainer {
          .missionHeading {
            font-size: 7vw;
          }

          .missionText {
            font-size: 3.5vw;
          }
        }

        .visionContainer {
          .visionHeading {
            font-size: 7vw;
          }

          .visionText {
            font-size: 3.5vw;
          }
        }
      }
    }

    .joinTeam {
      .content {
        .container {
          display: block;

          .element1 {
            width: 100%;

            .kontaktBtn {
              text-align: center;
            }
          }

          .element2 {
            margin-top: 2vh;

            img {
              width: 50vw;
              display: block;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .aboutus {
    .parkeringproblem {
      .content {
        .container {
          flex-direction: column;
          align-content: center;
          text-align: center;

          div {
            width: 50vw;
            flex: none;
            margin: auto;
          }
        }
      }
    }

    .problemsolving {
      .content {
        .imgContainer {
          width: 80%;

          .row1 {
            div {
              img {
                width: 80%;
              }
            }
          }

          .row2 {
            width: 100%;
          }
        }
      }
    }

    .mission_vision {
      .content {
        .missionContainer {
          .missionHeading {
            font-size: 50px;
          }

          .missionText {
            font-size: 25px;
          }
        }

        .visionContainer {
          .visionHeading {
            font-size: 50px;
          }

          .visionText {
            font-size: 25px;
          }
        }
      }
    }

    .joinTeam {
      .content {
        .sectionHeading {
          width: 100%;
        }

        .container {
          flex-direction: column;

          .element2 {
            img {
              display: block;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .aboutus {
    .mission_vision {
      .content {
        .missionContainer {
          .missionHeading {
            font-size: 10vw;
          }

          .missionText {
            font-size: 5vw;
          }
        }

        .visionContainer {
          .visionHeading {
            font-size: 10vw;
          }

          .visionText {
            font-size: 5vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .aboutus {
    .top {
      height: 84.4vh !important;
    }

    .mission_vision {
      .content {
        .missionContainer {
          .missionHeading {
            font-size: 5vw;
          }

          .missionText {
            font-size: 2.5vw;
          }
        }

        .visionContainer {
          .visionHeading {
            font-size: 5vw;
          }

          .visionText {
            font-size: 2.5vw;
          }
        }
      }
    }
  }
}