* {
  font-family: "Lato", sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  section {
    margin-bottom: 5vh;
  }

  .pageHeading {
    font-size: 48px;
    font-weight: 700;
    color: white;
  }

  .subHeading {
    font-size: 28px;
    font-weight: 400;
    color: white;
  }

  .sectionHeading {
    text-align: center;
    font-size: 36px !important;
    font-weight: 700 !important;
    padding-top: 5vh;
    margin-bottom: 6vh !important;
  }

  .paragraphHeading {
    font-size: 22px;
    font-weight: 700;
  }

  .iconHeading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2vh;
  }

  .footerHeading {
    font-size: 16px;
    font-weight: 600;
  }

  .paragraph {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0 !important;
  }

  .iconParagraph {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0 !important;
  }

  .bold {
    font-weight: 700;
  }

  .white {
    color: white;
  }

  .formlabel {
    font-size: 16px;
    font-weight: 700;
    margin: 12px 0 3px;
  }

  .formlabel-infobox {
    font-size: 16px;
    font-weight: 700;
    margin: 12px 0 3px;
  }

  .formlabel-optional {
    font-size: 16px;
    font-weight: 400;
  }

  .form-control {
    font-size: 18px !important;
  }

  .spaceBelow {
    padding-bottom: 5vh;
  }

  .spaceAbove {
    padding-top: 5vh;
  }

  @media only screen and (max-width: 1280px) {
    .pageHeading {
      text-align: center;
    }

    .subHeading {
      text-align: center;
    }
  }

  @media only screen and (max-width: 768px) {
    .pageHeading {
      font-size: 38px;
    }

    .subHeading {
      font-size: 18px;
    }

    .sectionHeading {
      font-size: 28px !important;
    }
  }

  @media only screen and (max-width: 500px) {
    .pageHeading {
      font-size: 28px;
    }
  }
}