@import "../Reusable/colors";

.reviewCarousel {
  p {
    font-size: 24px;
    color: white;
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  p {
    font-size: 5vw;
  }
}