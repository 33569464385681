@import "../../Reusable/colors";
.parkingItem {
    .eventParkingItemContainer {
        display: flex;
        height: 160px;
        margin-bottom: 20px;
        border-radius: 5px;
        box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.25);
        .item1 {
            width: 200px;
            img {
                height: 160px;
                width: 200px;
                object-fit: cover;
                border-radius: 4px 0 0 4px;
            }
        }
        .item2 {
            display: flex;
            width: 100%;
            padding: 10px 5px 5px 10px;
            .item2-1 {
                flex: 1;
                padding-right: 10px;
                .address {
                    margin-bottom: 8px;
                }
                .price {
                    margin-bottom: 3px;
                }
            }
            .item2-2 {
                width: 330px;
                border-left: thin black solid;
                padding-left: 10px;
                position: relative;
                .paragraphHeading {
                    margin-bottom: 8px;
                }
                .start {
                    margin-bottom: 5px;
                }
                .btn {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
                .lineShift {
                    display: none;
                }
            }
        }
    }
    .findParkingItemContainer {
        display: flex;
        height: 190px;
        margin-bottom: 20px;
        border-radius: 5px;
        box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.25);
        .item1 {
            width: 235px;
            img {
                height: 188px;
                width: 235px;
                //object-fit: contain;
                border-radius: 4px 0 0 4px;
            }
        }
        .item2 {
            display: flex;
            width: 100%;
            padding: 10px 5px 5px 10px;
            .item2-1 {
                flex: 1;
                padding-right: 10px;
                .address {
                    margin-bottom: 8px;
                }
                .lejeperiode {
                    margin-bottom: 3px !important;
                    .lineShift {
                        display: none;
                    }
                }
                .price {
                    margin-bottom: 3px !important;
                }
            }
            .item2-2 {
                width: 355px;
                border-left: thin black solid;
                padding-left: 10px;
                position: relative;
                .paragraphHeading {
                    .paragraphHeading {
                        margin-bottom: 8px;
                    }
                    margin-bottom: 8px;
                }
                .hours {
                    display: flex;
                    .box1 {
                        margin-right: 18px;
                    }
                }
                .btnContainer {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    .lej {
                        padding: 2px 30px;
                    }
                }
                .btn {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1083px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 215px;
            .item1 {
                width: 240px;
                img {
                    height: 213px;
                    width: 240px;
                }
            }
            .item2 {
                padding: 5px 5px 5px 10px;
                font-size: 14px;
            }
        }
        .findParkingItemContainer {
            height: 235px;
            .item1 {
                width: 240px;
                img {
                    height: 233px;
                    width: 240px;
                }
            }
            .item2 {
                padding: 5px 5px 5px 10px;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 225px;
            .item1 {
                width: 266.6666666667px;
                img {
                    height: 223px;
                    width: 266.6666666667px;
                    border-radius: 4px 0 0 4px;
                }
            }
            .item2 {
                padding: 10px;
                flex-direction: column;
                justify-content: space-between;
                .item2-1 {
                    flex: none;
                    margin-bottom: 10px;
                    .other {
                        margin-bottom: 0;
                    }
                }
                .item2-2 {
                    width: 100%;
                    padding-left: 0px;
                    border-left: none;
                    .heading {
                        font-size: 16px;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                    .end {
                        margin-bottom: 0px;
                    }
                    .start {
                        margin-bottom: 5px;
                    }
                    .hideHeading {
                        display: none;
                    }
                }
            }
        }
        .findParkingItemContainer {
            height: 265px;
            .item1 {
                width: 266.6666666667px;
                img {
                    height: 263px;
                    width: 266.6666666667px;
                    border-radius: 4px 0 0 4px;
                }
            }
            .item2 {
                padding: 10px;
                flex-direction: column;
                font-size: 14px;
                justify-content: space-between;
                .item2-1 {
                    flex: none;
                    margin-bottom: 10px;
                    .other {
                        margin-bottom: 0;
                    }
                }
                .item2-2 {
                    width: 100%;
                    padding-left: 0px;
                    border-left: none;
                    .heading {
                        font-size: 16px;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                    .end {
                        margin-bottom: 0px;
                    }
                    .start {
                        margin-bottom: 5px;
                    }
                    .hideHeading {
                        display: none;
                    }
                    .btnContainer {
                        .lej {
                            display: hidden;
                            width: 100%;
                            padding: 5px 30px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 300px;
            .item1 {
                img {
                    padding: 18px;
                }
            }
            .item2 {
                padding-left: 0px;
                .item2-2 {
                    height: 110px;
                }
            }
        }
        .findParkingItemContainer {
            height: 380px;
            .item1 {
                img {
                    padding: 18px;
                    box-sizing: border-box;
                }
            }
            .item2 {
                padding-left: 0px;
                .item2-2 {
                    height: 125px;
                    .btnContainer {
                        width: 100%;
                        .lej {
                            display: block;
                            padding: 5px 30px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 675px) {
    .parkingItem {
        .findParkingItemContainer {
            height: 390px;
            .item1 {
                img {
                    padding: 18px;
                    box-sizing: border-box;
                }
            }
            .item2 {
                padding-left: 0px;
                .item2-1 {
                    .lejeperiode {
                        margin-bottom: 5px;
                        .lineShift {
                            display: block;
                        }
                    }
                }
                .item2-2 {
                    .lineShift {
                        display: block;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 645px) {
    .parkingItem {
        .findParkingItemContainer {
            height: 750px;
            flex-direction: column;
            .item1 {
                width: 100%;
                height: calc(90vw / 1.333);
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                }
            }
            .item2 {
                width: 100%;
                padding: 10px;
                padding-top: 0;
                justify-content: space-between;
                flex: 1;
                padding-left: 20px;
                padding-right: 20px;
                .item2-1 {
                    padding-right: 0;
                    .address {
                        margin-bottom: 5px;
                    }
                    .lejeperiode {
                        margin-bottom: 3px;
                        .lineShift {
                            display: none;
                        }
                    }
                    .price {
                        margin-bottom: 3px;
                    }
                }
                .item2-2 {
                    height: 130px;
                }
            }
        }
    }
}

@media only screen and (max-width: 625px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 680px;
            flex-direction: column;
            .item1 {
                width: 100%;
                height: calc(90vw / 1.333);
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                }
            }
            .item2 {
                width: 100%;
                padding: 0px 20px 10px;
                flex: 1;
                .item2-1 {
                    padding-right: 0px;
                    .address {
                        margin-bottom: 5px;
                    }
                    .price {
                        margin-bottom: 3px;
                    }
                }
            }
        }
        .findParkingItemContainer {
            height: 670px;
        }
    }
}

@media only screen and (max-width: 570px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 650px;
        }
        .findParkingItemContainer {
            height: 670px;
        }
    }
}

@media only screen and (max-width: 525px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 620px;
        }
    }
}

@media only screen and (max-width: 460px) {
    .parkingItem {
        .findParkingItemContainer {
            height: 630px;
            .item1 {
                width: 100%;
                height: calc(90vw / 1.333);
                padding: 0;
                img {
                    height: 95%;
                    width: 95%;
                    border-radius: 4px;
                    padding: 0;
                }
            }
            .item2 {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: 430px) {
    .parkingItem {
        .eventParkingItemContainer {
            height: 560px;
            .item1 {
                width: 100%;
                height: calc(90vw / 1.333);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                img {
                    height: 95%;
                    width: 95%;
                    border-radius: 4px;
                    padding: 0;
                }
            }
            .item2 {
                width: 100%;
                padding: 10px;
                padding-top: 0;
                justify-content: space-between;
                flex: 1;
                padding: 0 10px;
                .item2-1 {
                    padding-right: 0px;
                    .address {
                        font-size: 20px;
                        margin-bottom: 2px;
                    }
                    .price {
                        margin-bottom: 3px;
                    }
                }
                .item2-2 {
                    .heading {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                    .start {
                        margin-bottom: 5px;
                    }
                    .lineShift {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .paragraph {
        font-size: 16px;
    }
    .parkingItem {
        .findParkingItemContainer {
            height: 580px;
            .item2 {
                .item2-2 {
                    /* .hours {
            .box1 {
                margin-right: 0;
            }
            } */
                    .hours {
                        .box1 {
                            margin-right: 5px;
                        }
                        .box1,
                        .box2 {
                            p {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}