@import "../Reusable/colors";

.faq {
  .container {
    max-width: 100%;
    padding: 0;
  }

  a {
    color: black;
    font-weight: 700;
    text-decoration: underline;
  }

  .faqContainer {
    .container2 {
      height: auto;
      border-radius: 15px;

      #logo {
        width: 20px;
        margin-right: 10px;
      }

      .option {
        display: flex;
        align-items: center;
        text-align: left;
      }

      .label {
        font-size: 22px;
        font-weight: 700;
        color: black;
        margin: auto 0;
        padding: 1.5% 1%;
      }

      .toggle:hover,
      :focus {
        text-decoration: none !important;
      }

      .text {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .faq {
    .faqContainer {
      .container {
        .option {
          height: auto;
        }

        #logo {
          width: 20px;
        }

        .label {
          font-size: 19px;
          padding: 2% 0.5%;
        }

        .text {
          font-size: 17px;
        }
      }
    }
  }
}

@media only screen and (max-width: 376px) {
  .faq {
    .faqContainer {
      .container {
        width: 85%;
        margin: 0 auto;
        padding: 0;

        .option {
          height: auto;
        }

        .label {
          font-size: 18px;
          padding: 2.5% 0;
        }

        .text {
          font-size: 16px;
        }
      }
    }
  }
}