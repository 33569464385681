@import "../Reusable/colors";

.info {
  position: relative;

  .info-icon {
    height: 18px;
    margin-left: 2px;
    margin-top: -15px;
    transition: all 1s ease;

    &:hover+.topPlacement .info-box {
      display: block;
    }
  }

  .topPlacement .info-box {
    font-size: 14px;
    border: 1px solid $blue;
    border-radius: 4px;
    background-color: white;
    padding: 10px;
    width: 25vw;
    display: none;
    position: absolute;
    left: auto;
    top: auto;
    bottom: 40px;
    right: -22px;

    .text {
      white-space: pre-wrap;
      margin: auto;
    }

    &:before {
      content: "";
      position: absolute;
      transform: rotate(-90deg) scaleY(0.6);
      top: auto;
      left: auto;
      bottom: -20px;
      right: 20px;
      border: 10px solid transparent;
      border-right: 10px solid $blue;
    }

    &:after {
      content: "";
      position: absolute;
      transform: rotate(-90deg) scaleY(0.6);
      top: auto;
      left: auto;
      bottom: -18px;
      right: 21px;
      border: 9px solid transparent;
      border-right: 9px solid white;
    }
  }
}

// info box moves to the top
@media only screen and (max-width: 850px) {
  .info {
    .topPlacement .info-box {
      width: 50vw;
      right: -210px;

      &:before {
        transform: rotate(-90deg) scaleY(0.6);
        top: auto;
        left: auto;
        bottom: -20px;
        right: 207px;
      }

      &:after {
        transform: rotate(-90deg) scaleY(0.6);
        top: auto;
        left: auto;
        bottom: -18px;
        right: 208px;
      }
    }
  }
}

@media only screen and (max-width: 556px) {
  .info {
    .topPlacement .info-box {
      right: -120px;

      &:before {
        right: 118px;
      }

      &:after {
        right: 119px;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .info {
    .topPlacement .info-box {
      right: -80px;

      &:before {
        right: 78px;
      }

      &:after {
        right: 79px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .info {
    .topPlacement .info-box {
      right: -35px;

      &:before {
        right: 35px;
      }

      &:after {
        right: 36px;
      }
    }
  }
}

@media only screen and (max-width: 340px) {
  .info {
    .topPlacement .info-box {
      right: auto;
      left: -15px;

      &:before {
        right: auto;
        left: 15px;
      }

      &:after {
        right: auto;
        left: 16px;
      }
    }
  }
}