@import "./colors";
* {
  // Top section buttons
  .element1 {
    .topButton {
      background-color: $blue;
      font-weight: 800;
      width: 205px;
      font-size: 18px;
      height: 50px;
      border: none;
      display: block;
      margin-top: 2vh;
    }
    .topButton:hover,
    .topButton:focus,
    .topButton:active {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    }
  }
  // Normal Btns (Submit btns and redirection btns)
  .regular-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    #btn-submit {
      width: 120px;
      height: 42px;
      background-color: $pink;
      border: none;
      align-items: center;
      padding: 0 !important;
    }
  }

  #redirectionToWaitingListBtn {
    width: 152px;
    height: 38px;
    background-color: $blue;
  }
}
