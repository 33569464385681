@import "../Reusable/colors";

.findparkering {

  .content {
    width: 1200px;
    margin: auto;
  }

  .container {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .top {
    margin-top: 58px;
    height: 93.8vh;
    background-image: url("../../Assets/Findparkering/woman-car-private-parking-frontyard.jpg");
    background-position: 50% 80%;
    background-size: cover;
    background-repeat: no-repeat;

    .bg {
	 //  position: absolute;
      height: 100%;
      background: rgb(0, 99, 219);
      background: linear-gradient(0deg,
          rgba(0, 99, 219, 0.5032387955182073) 0%,
          rgba(255, 0, 100, 0.500437675070028) 100%);

      .content {
        height: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        margin: auto;
        align-items: center;

        .element1 {
          .btn-primary {
            margin: 0;
            padding: 0;
          }
        }

        .element2 {
          iframe {
            border: none;
            height: 338px;
            width: 600px;
          }
        }
      }
    }
  }

  .mobileVideo {
    display: none;
  }

  /* Fordele styles  */
  .fordele {
    .content {
      .container {
        display: flex;
        justify-content: space-between;

        .element1 {
          width: 45%;
        }

        .vertical-line {
          display: inline-block;
          border-left: 1px solid #c0c0c0;
        }

        .element2 {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 30px 0;

          .fordeleMobileOnly {
            display: none;
          }

          .check {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
              font-weight: 600;
              font-size: 29px !important;
              margin: 0 0 0 5%;
            }

            .checkbox {
              height: 80px;
              width: auto;
            }
          }
        }
      }
    }
  }

  /* så nemt at leje */
  .lejnemt {
    .content {
      text-align: center;

      .subHeading {
        font-weight: 500;
        font-size: 36px;
      }

      .bubblesContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        width: 100%;

        .element {
          width: 100%;
          position: relative;

          img {
            width: 100%;
          }

          p {
            position: absolute;
            top: 60%;
            transform: translate(0, -60%);
            font-size: 1.8rem;
            font-weight: 500;
            padding: 0 15%;
          }
        }
      }
    }
  }

  /* REDIRECT TO EVENT PARKING  SECTION*/
  .eventLink1 {
	position: relative;
	display: flex;
	justify-content: center;
	height: 300px;

    .bg {
	  position: absolute;
      height: 300px;
      width: 100%;
      background: linear-gradient(to right, #0063db 0%, #ff0084 100%);
      transform: skewY(3deg);
    }

    .content {
      position: relative;
	  margin: 0px;
	  margin-top: 35px;

      .bannerHeading {
		text-align: center;
        padding-top: 0;
        margin-bottom: 20px;
      }

      .eventContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 130px;

        .element1 {
          width: 95px;
          height: 82px;
        }

        .element2 {
          width: 58px;
          height: 58px;
          display: flex;
          align-self: flex-end;
          margin-left: -20px;
        }

        .element3 {
          width: 150px;
          height: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .regular-button {
        #btn-submit {
          margin: 35px;
          background-color: $blue;
        }
      }

      .containerUnder592px {
        display: none;
      }
    }
  }

  .pspotstyper {
    .content {
      .box {
        text-align: center;

        .container {
          display: flex;

          .element1,
          .element2,
          .element3 {
            width: 100%;

            .fadingBackground {
              background: linear-gradient(180deg, white, $blue);
              margin-bottom: 20px;
              padding-bottom: 4px;

              img {
                background: white;
                width: 97%;
                padding: 13px 42px;
              }
            }
          }

          .element1,
          .element2 {
            margin-right: 40px;
          }

          .element3 {
            .wrapping {
              display: flex;
              justify-content: center;
              align-items: center;

              .overlappingMessage {
                position: absolute;
                z-index: 2;
                padding: 15px;
                border-radius: 18px;
                background: rgba(196, 196, 196, 0.6);
                display: flex;
                align-items: center;

                p {
                  font-weight: 900;
                  font-size: 40px;
                  margin-bottom: 0;
                  color: #ff0084;
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  line-height: initial;
                }
              }

              .fadingBackground {
                position: relative;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

  /* "Hvilken type biler kan leje parkering" section */
  .carType {
    .content {
      .container {
        display: flex;
        justify-content: space-between;

        .element1 {
          width: 45%;

          .paragraph {
            p {
              margin-bottom: 30px;
            }
          }
        }

        .vertical-line {
          display: inline-block;
          border-left: 1px solid #c0c0c0;
          height: 275px;
        }

        .element2 {
          width: 40%;
          display: flex;
          flex-direction: column;

          .carTypeMobileOnly {
            display: none;
          }

          .check {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;

            p {
              font-weight: 600;
              font-size: 29px !important;
              line-height: 1.25;
              margin-left: 5%;
              margin-bottom: 0;
            }

            .checkbox {
              height: 80px;
              width: auto;
            }

            .grayCheckbox {
              height: 75px;
              width: auto;
              margin-right: 11px;
            }
          }
        }
      }
    }
  }

  .waitinglist {
    .content {
      .waitinglistContainer {
        .waitinglist1200pxUnder {
          display: none;

          .paragraph {
            text-align: center;
            border: 0px solid red;
          }
        }

        .waitinglist1200pxOver {
          display: flex;

          .element1 {
            flex: 10;
            margin-right: 4%;
            margin-top: 8%;
            border: 0px solid red;

            .keys {
              text-align: center;
              padding-top: 10vh;

              img {
                width: 200px;
              }
            }
          }

          .element2 {
            flex: 15;
            border: 0px solid purple;
            margin-right: 100px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .findparkering {
    .content {
      width: 80% !important;
    }

    .top {
      height: 60vw;

      .bg {
        .content {
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;

          .element1 {
            margin-bottom: 2vh;
            margin-right: 0;

            .topButton {
              display: initial;
            }
          }

          .element2 {
            iframe {
              height: 225px;
              width: 400px;
            }
          }
        }
      }
    }

    .fordele {
      .content {
        .container {
          height: auto;
          flex-direction: column;

          .element1 {
            width: 100%;
          }

          .element2 {
            padding: 0;
            width: 100%;

            .check {
              padding-top: 2vh;
            }
          }
        }
      }
    }

    .lejnemt {
      .content {
        .bubblesContainer {
          width: 90%;

          .element {
            p {
              font-size: 1.7vw;
              margin-top: 4px;
            }
          }
        }
      }
    }

    .parkingList {
      background: white;
      height: auto !important;

      .content {
        width: auto !important;
      }
    }

    .waitinglist {
      .content {
        width: auto !important;

        .waitinglistContainer {
          height: auto;
          width: 800px;
          margin: auto;

          .waitinglist1200pxUnder {
            display: block;
          }

          .waitinglist1200pxOver {
            display: none;
          }
        }
      }
    }

    .carType {
      .content {
        .container {
          height: auto;
          flex-direction: column;

          .element1 {
            width: 100%;
          }

          .vertical-line {
            display: none;
          }

          .element2 {
            padding: 0;
            width: 100%;

            .grayCheckbox {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .findparkering {
    .pspotstyper {
      .content {
        .box {
          padding-bottom: 0px;
          padding-top: 0px;
          height: auto;

          .container {
            flex-direction: column;
            align-content: center !important;
            text-align: center;
            justify-content: center;

            .element1,
            .element2,
            .element3 {
              width: 50%;
              height: auto;
              margin: 3% auto;
              flex: none;
            }

            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 45px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .findparkering {
    .waitinglist {
      .content {
        .waitinglistContainer {
          width: 90%;
        }
      }
    }
  }

  .findparkering {
    .waitinglist {
      .content {
        .waitinglistContainer {
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .findparkering {
    .top {
      .bg {
        .content {
          .element2 {
            iframe {
              height: 180px;
              width: 318px;
            }
          }
        }
      }
    }

    .pspotstyper {
      .content {
        .box {
          .container {
            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .findparkering {
    .top {
      .element2 {
        display: none;
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;

      iframe {
        border-width: 0px;
        width: 80%;
        height: 20%;
      }
    }

    .fordele {
      .content {
        .container {
          display: block;

          .element1 {
            display: none;
          }

          .vertical-line {
            display: none;
          }

          .element2 {
            margin: 0;
            width: 100%;

            .fordeleMobileOnly {
              display: block;
            }

            .check {
              p {
                font-size: 1.375rem !important;
                line-height: 1.2;
              }

              .checkbox {
                height: 60px;
              }
            }
          }
        }
      }
    }

    .carType {
      .content {
        .container {
          display: block;

          .element1 {
            display: none;
          }

          .vertical-line {
            display: none;
          }

          .element2 {
            margin: 0;
            width: 100%;

            .carTypeMobileOnly {
              display: block;
            }

            .check {
              p {
                font-size: 22px !important;
                line-height: 1.2;
              }

              .checkbox {
                height: 60px;
              }

              .grayCheckbox {
                height: 60px;
              }
            }
          }
        }
      }
    }
  }

  .eventLink1 {

    .content {
      .eventContainer {
        display: none !important;
      }

      .containerUnder592px {
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;

        .icons {
          display: flex;
          width: 90%;
          justify-content: center;
          align-content: center;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          .tickets {
            width: 58px;
            height: 58px;
            display: flex;
            margin-top: 8px;
          }

          .ball {
            width: 45px;
            height: 45px;
            display: flex;
            margin: 14px 40px 0 40px;
          }

          .tivoli {
            width: 80px;
            height: 70px;
            display: flex;
          }
        }
      }

      .regular-button {
        #btn-submit {
          margin: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .findparkering {
    .pspotstyper {
      .content {
        .box {
          .container {

            .element1,
            .element2,
            .element3 {
              width: 80%;
            }

            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .findparkering {
    .top {
      .bg {
        .content {
          .element1 {
            h1 {
              font-size: 30px;
            }
          }

          .element2 {
            display: none;
          }
        }
      }
    }

    .lejnemt {
      .content {
        .bubblesContainer {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .element {
            img {
              width: 90%;
              padding: 5% 0;
            }

            p {
              font-size: 4.5vw;
              padding: 0 20%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .findparkering {
    .top {
      height: 84.4vh !important;

      .bg {
        .content {
          display: flex;
          align-items: center;

          .element2 {
            display: none;
          }
        }
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 400px) {
  .findparkering {
    .pspotstyper {
      .content {
        .box {
          .container {

            .element1,
            .element2,
            .element3 {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .findparkering {
    .pspotstyper {
      .content {
        .box {
          .container {
            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 335px) {
  .findparkering {
    .pspotstyper {
      .content {
        .box {
          .container {
            .element3 {
              .wrapping {
                .overlappingMessage {
                  p {
                    font-size: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .udlejparkering {
    .pspotstyper {
      .content {
        .box {
          .container {
            .element3 {
              .wrapping {
                .overlappingMessage {
                  margin-bottom: 3%;
                  padding: 12px;

                  p {
                    font-size: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}