@import "../../Reusable/colors";

.rentConfirmModal {
    .modal-dialog {
        max-width: 1000px;
    }

    .paragraphHeading {
        text-align: center;
    }

    .a {
        margin: 5vh 0;
    }

    .b {
        margin: 30px 0;
    }

    .formContainer {
        height: auto;
        width: 90%;
        margin: auto;
    }

    .checkIcon {
        display: block;
        margin: auto;

        svg {
            display: block;
            margin: auto;
        }
    }

    .confirmationContainer {
        display: block;
        width: 90%;
        margin: auto;
        margin-top: 30px;
    }
}

.rentConfirmPopUp {
    .lej {
        padding: 2px 30px;
    }
}

@media only screen and (max-width: 1280px) {
    .rentConfirmModal {
        .modal-dialog {
            max-width: 90%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .rentConfirmPopUp {
        .lej {
            width: 100%;
            padding: 5px 30px;
        }
    }

    .rentConfirmModal {
        .modal-dialog {
            max-width: 95%;
        }
    }
}

@media only screen and (max-width: 350px) {
    .rentConfirmModal {
        .formContainer {
            width: 95%
        }
    }
}