@import "../../Reusable/colors";

.eventConfirmForm {
    background-color: white;
    // padding: 20px;
    border-radius: 15px;
    color: black;

    .inputgroup-right-radius {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .betalingInfo {
        margin-bottom: 1rem !important;
    }
}

@media only screen and (max-width: 850px) {
    .eventConfirmForm {
        .form-row {
            flex-direction: column;
        }
    }
}