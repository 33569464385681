@import "../../Reusable/colors";

.brand1 {
  .slick-arrow {
    z-index: 99;
  }

  .slick-next:before {
    color: $pink;
    font-size: 50px;
  }

  .slick-prev:before {
    color: $pink;
    font-size: 50px;
  }

  .slick-prev {
    width: 35px;
    left: -65px;
    height: 60px;
  }

  .slick-next {
    width: 35px;
    right: -58px;
    height: 60px;
  }

  .slick-prev:before {
    font-family: "FontAwesome";
    content: url(../../../Assets/Aboutus/chevron-left.svg);
  }

  .slick-next:before {
    font-family: "FontAwesome";
    content: url(../../../Assets/Aboutus/chevron-right.svg);
  }

  .slick-list {
    .slick-track {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .itemContainer {
        border: 0px solid red;
        height: auto;
        display: flex;
        flex-direction: column;

        div {
          img {
            border: 0px solid blue;
            margin: auto;
            width: auto;
            height: 140px;
          }
        }

        p {
          text-align: center;
          margin-top: 8px;
          font-size: 18px !important;
          font-weight: 400;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .brand1 {
    .slick-prev {
      left: -45px;
    }

    .slick-next {
      right: -45px;
    }

    .slick-list {
      .slick-track {
        .itemContainer {
          border: 0px solid red;
          height: auto;

          div {
            img {
              border: 0px solid blue;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1082px) {
  .brand1 {
    .slick-prev {
      left: -20px;
    }

    .slick-next {
      right: -18px;
    }

    .slick-list {
      .slick-track {
        .itemContainer {
          border: 0px solid red;
          height: auto;

          div {
            img {
              border: 0px solid blue;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .brand1 {
    .slick-prev {
      left: -38px;
    }

    .slick-next {
      right: -30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .brand1 {
    .slick-prev {
      left: -41px;
    }

    .slick-next {
      right: -42px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .brand1 {
    .slick-prev {
      left: 14px;
    }

    .slick-next {
      right: 13px;
    }
  }
}

@media only screen and (max-width: 450px) {
  h1 {
    font-size: 8vw;
  }
}