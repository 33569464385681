@import "../Reusable/colors";

.event {
  overflow-x: hidden;
  max-width: 100vw;

  .content {
    width: 1200px;
    margin: auto;
  }

  .top {
    margin-top: 58px;
    height: 93.8vh;
    background-image: url("../../Assets/Event/football-game-match-aarhus-agf.JPG");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .bg {
      height: 100%;
      background: rgb(0, 99, 219);
      background: linear-gradient(
        0deg,
        rgba(0, 99, 219, 0.5032387955182073) 0%,
        rgba(255, 0, 100, 0.500437675070028) 100%
      );

      .content {
        height: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        margin: auto;
        align-items: center;

        .element2 {
          iframe {
            border: none;
            height: 338px;
            width: 600px;
          }
        }
      }
    }
  }

  .mobileVideo {
    display: none;
  }

  //   EVENT DESCRIPTION CODE
  .desc {
    .content {
      display: flex;
      align-items: center;

      .element1 {
        img {
          width: 500px;
        }
      }

      .element2 {
        .paragraph {
          padding-bottom: 0.5vh;
        }
      }
    }
  }

  // EVENT LIST CODE
  .eventParkingList {
    .content {
      width: 100% !important;
    }
  }

  .waitinglist {
    .content {
      .sectionHeading {
        margin-bottom: 6vh;
      }

      .waitinglistContainer {
        .waitinglist1200pxUnder {
          display: none;

          .paragraph {
            text-align: center;
            border: 0px solid red;
          }
        }

        .waitinglist1200pxOver {
          display: flex;

          .element1 {
            flex: 8;
            margin-right: 4%;
            margin-top: 3%;
            border: 0px solid red;

            .keys {
              padding-top: 4vh;
              text-align: center;

              img {
                width: 200px;
              }
            }
          }

          .element2 {
            flex: 8;
            border: 0px solid purple;
            margin-right: 100px;
          }
        }
      }
    }
  }

  //EVENT LINK CODE
  .eventLink {
    background: linear-gradient(to right, #0063db 0%, #ff0084 100%);
    transform: skewY(3deg);
    margin: 10vh 0 5vh 0;

    .content {
      transform: skewY(-3deg);
      height: 100%;
      display: block;
      text-align: center;
      transform: skewY(-3deg);

      .eventButton {
        font-size: 1rem;
        padding: 6px 12px;
        width: 120px;
        background-color: #ff0084;
        border: none;
      }

      .eventButton:hover,
      .eventButton:focus,
      .eventButton:active {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
      }
    }
  }

  //EVENT FORM CODE
  .eventForm {
    .content {
      h2 {
        width: 80%;
        margin: 0 auto 4vh;
      }

      width: auto !important;

      .eventformContainer {
        height: auto;
        width: 400px;
        margin: auto;
      }
    }
  }

  .faq {
    .faqHeading {
      font-size: 28px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 4vh;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .event {
    .content {
      width: 80% !important;
    }

    .top {
      height: 60vw;

      .bg {
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          .element1 {
            margin-bottom: 2vh;
            margin-right: 0;

            .topButton {
              display: initial;
            }
          }

          .element2 {
            iframe {
              height: 225px;
              width: 400px;
            }
          }
        }
      }
    }

    .desc {
      .content {
        flex-direction: column;

        .element1 {
          img {
            width: 30vw;
            display: block;
            margin: auto;
          }
        }

        .element2 {
          margin-left: 0;

          p {
            line-height: 1.5;
          }
        }
      }
    }

    .waitinglist {
      .content {
        width: auto !important;

        .waitinglistContainer {
          height: auto;
          width: 800px;
          margin: auto;

          .waitinglist1200pxUnder {
            display: block;
          }

          .waitinglist1200pxOver {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .event {
    .waitinglist {
      .content {
        .waitinglistContainer {
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .event {
    .eventParkingList {
      .content {
        .scroll-container {
          margin-top: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .event {
    .top {
      .bg {
        .content {
          .element2 {
            iframe {
              height: 180px;
              width: 318px;
            }
          }
        }
      }
    }

    .desc {
      .content {
        .element1 {
          img {
            width: 60%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .event {
    .top {
      .bg {
        .element1 {
          margin-bottom: 0 !important;

          p {
            text-align: center;
          }
        }

        .element2 {
          display: none;
        }
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;

      iframe {
        border-width: 0px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .eventLink {
    .content {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .event {
    .top {
      .bg {
        .content {
          .element2 {
            display: none;
          }
        }
      }
    }

    .eventParkingList {
      .content {
        width: 100%;
      }
    }

    .eventForm {
      .content {
        .eventformContainer {
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .event {
    .eventLink {
      .content {
        h3 {
          font-size: 24px;
        }
      }
    }
  }
}

@media only screen and (max-height: 500px) {
  .event {
    .top {
      height: 84.4vh !important;

      .bg {
        .content {
          display: flex;
          align-items: center;

          .element2 {
            display: none;
          }
        }
      }
    }

    .mobileVideo {
      display: flex;
      justify-content: center;
    }
  }
}
